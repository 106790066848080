.hx-list {
    margin: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    width: 100%;

    &.hover {
        .hx-list-item:hover {
            background-color: var(--surface-color);
        }
    }

    .hx-list-item ~ .hx-list-item {
        border-top: none;
    }

    .hx-list-item {
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        background: var(--main-background);
        display: flex;
        padding: 0 12px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
        height: 36px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .icon-x {
            font-size: 14px;
        }

        div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }

        .title {
            color: var(--primary-font);
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .hx-description {
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &.big {
        .hx-list-item {
            height: 48px;
        }
    }

    .hx-list-item.block-item {
        border: 1px solid var(--border-color);
        border-radius: 3px;
        margin-bottom: 8px;
    }
}
