.login-container {
    display: block;
    margin: auto;
    box-shadow:
        0 1px 0 0 rgba(0, 0, 0, 0.05),
        0 0 20px 0 rgba(0, 0, 0, 0.15);
    padding: 50px 60px;
    max-width: 570px !important;
    line-height: 1.5;
    font-size: 14px;
    position: relative;
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.unauth-header {
    border: none;
    margin: calc(2rem - 0.14285714em) 0 1rem;
    padding: 0 0;
    text-transform: none;
    line-height: 32px;
    font-size: 28px;
    font-weight: 700;
    font-size: 28px;
}

.unauth-background {
    overflow: auto;
    // background-image: url("https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/N13e7awhgiljgescq/speech-bubbles-on-white-background-animated-flat-design_r6gg9ndkl_thumbnail-full15.png");
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-position: center;
    // background-size: cover;
    background: #f7f8fa;
    color: #435a6f;
    -webkit-flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    display: block;
    flex: 1;
    width: 100%;
    height: 100%;
}

.login-form-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

.login-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    a:hover {
        text-decoration: underline;
    }
    margin-top: 14px;
}

.login-footer-element {
    padding: 0px 20px 0px 20px;
}

.login-footer-element:hover {
    text-decoration: underline;
}

.register-left-panel {
    margin: 36px auto;
    border-radius: 5px;
    display: block;
    flex: 1;
    width: 560px;
    height: 800px;
    background-color: white;
    padding: 50px 64px 64px 64px;
    box-shadow:
        0 1px 0 0 rgba(0, 0, 0, 0.05),
        0 0 34px 0 rgba(0, 0, 0, 0.22);
}

.register-left-panel-buttons {
    margin-bottom: 60px;
}

.horizontal-divider {
    height: 21px;
    border-right: solid 1px #32495f;
    padding-right: 15px;
}
