.dropdown {
    position: relative;
}

.open > .dropdown-menu {
    display: block;
}

.dropdown-menu {
    position: fixed;
    z-index: 1000;
    margin: 5px 0px;
    background-color: var(--white);
    color: var(--primary-font);
    border-radius: var(--hx-menu-radius-px);
    display: block;
    min-width: 240px;
    max-width: 240px;
    outline: 1px solid transparent;
    overflow-y: hidden;
    transition:
        height 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        margin-top 267ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 267ms cubic-bezier(0.4, 0, 0.2, 1);
    will-change: height, margin-top, opacity;
    box-shadow:
        inset 0px 0px 0px 1px var(--border-color),
        0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 0px; // <- important if dropdown-menu is a ul element
    font-weight: 400;
}

.dropdown-body {
    display: flex;
    flex-direction: column;
    &:not(.noheight) {
        max-height: 220px;
    }
    overflow-y: auto;
}

.dropdown-body.tall {
    max-height: 314px;
}

.mat-mdc-menu-item {
    height: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important;
    min-height: 40px !important;
}

.dropdown-line {
    display: inline-flex;
    height: 40px;
    min-height: 40px;
    cursor: pointer;
    outline: none;
    width: 100%;
    padding: 0 8px 0px 16px;
    align-items: center;
}

.dropdown-line.disabled {
    cursor: default;
    color: var(--secondary-font);
}

.dropdown-line:not(.disabled):hover {
    background-color: var(--surface-color);
}

.dropdown-header {
    height: 48px;
    padding: 0px 8px;
    padding-top: 8px;
    position: relative;
}

.dropdown-footer {
    border-top: 1px solid var(--border-color);
    height: 48px;
    padding: 0 16px;
}

.dropdown-footer-big {
    @extend .dropdown-footer;
    height: 64px;
}

.dropdown-part {
    padding: 0px 14px;
    padding-bottom: 14px;
}

.dropdown-input {
    width: 100%;
}

.focused-dropdown-line {
    background-color: var(--surface-color);
}

.dropdown-line .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 42px;
    flex: 0 1 auto;
}

.line-selected {
    color: #262626;
    text-decoration: none;
    background-color: var(--surface-color);
}

.mat-mdc-menu-content {
    min-width: 240px;
    width: fit-content;
    background: var(--menu-background);
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.mat-mdc-menu-panel {
    max-width: fit-content !important;
}
.h-dropdown-menu {
    .mat-mdc-menu-content {
        max-height: 100%;
        width: fit-content;
    }
    &.mat-mdc-menu-panel {
        border-radius: var(--hx-menu-radius-px);
        min-width: 220px !important;
        width: fit-content !important;
        max-width: fit-content !important;
        // min-width: fit-content !important;

        .mat-mdc-menu-content {
            min-width: 220px;
            width: fit-content;
            max-width: fit-content;
            // min-width: fit-content;
        }
    }
}

.mat-mdc-menu-item.dropdown-line {
    height: 40px !important;
    line-height: inherit !important;
    padding: 0 0px 0px 16px !important;
}

.filterview-filters-dropdown.mat-mdc-menu-panel {
    max-width: 1200px;

    .mat-mdc-menu-content {
        min-width: 240px;
        max-width: 1200px;
        max-height: none;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.h-dropdown-menu.huge.mat-mdc-menu-panel {
    max-width: 320px;
    .dropdown-body {
        max-height: 100%;
    }
    .mat-mdc-menu-content {
        height: fit-content;
        min-width: 320px;
        max-width: 320px;
    }
}

.h-dropdown-menu.chunk-popup.mat-mdc-menu-panel {
    --menu-width: 540px;
    max-width: var(--menu-width);
    border-radius: var(--hx-menu-radius-px);
    .dropdown-body {
        max-height: 100%;
    }
    .mat-mdc-menu-content {
        height: fit-content;
        min-width: var(--menu-width);
        max-width: var(--menu-width);
    }
}

.h-dropdown-menu.notifications.mat-mdc-menu-panel {
    max-width: 420px;
    .dropdown-body {
        max-height: 100%;
    }
    .mat-mdc-menu-content {
        height: fit-content;
        min-width: 420px;
        max-width: 420px;
    }
}

.h-dropdown-menu.discoverytree.mat-mdc-menu-panel {
    max-width: 460px;
    .dropdown-body {
        max-height: 100%;
    }
    .mat-mdc-menu-content {
        height: fit-content;
        min-width: 460px;
        max-width: 460px;
    }
}
.dropdown-menu-chunk-popup {
    @extend .dropdown-menu;
    max-width: 540px;
    min-width: 540px;
}

// ? working for resizing mat-manu correctly: insert a class directly inside <mat-menu tag with one of those classes:
.menu-xwide {
    width: 480px;
    max-width: 480px;
    min-width: 480px;
    display: block;
}

.menu-wide {
    width: 320px;
    max-width: 320px;
    min-width: 320px;
    display: block;
}

.menu-large {
    width: 460px;
    max-width: 460px;
    min-width: 460px;
    display: block;
}

mat-select:not(.w-100) {
    width: fit-content !important;
    min-width: 0px;
    .mat-mdc-select-value {
        width: fit-content;
        min-width: 2ch;
        max-width: 25ch;
    }
    .mat-mdc-select-placeholder {
        width: fit-content;
        min-width: 10ch;
    }
}

::ng-deep .mat-form-field-infix,
::ng-deep .mat-mdc-form-field-infix {
    min-width: 75px !important;
    width: fit-content !important;
}

.mat-mdc-menu-item:not([disabled]):hover {
    background-color: var(--surface-color) !important;
}
