i[class|="icon"] {
    // margin: 0;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    line-height: unset;
}

.icon-mail {
    color: var(--red);
}

.icon-brand17 {
    color: var(--primary-font);
}

// intercom
.icon-brand10,
.icon-brand10:before {
    color: #286efa;
}

.menu-icon {
    margin: 0 8px 0 0px;
    color: inherit;
}

.icon-x {
    &:not(.nostyle) {
        font-size: 16px;
        color: var(--secondary-font);
        cursor: pointer;
        &:hover {
            color: var(--primary-font);
        }
    }
}

.clickable-icon-dark {
    color: #8e8e8e;
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    margin-right: 3px;
    border-radius: 5px;
    line-height: 18px;
}

.clickable-icon {
    color: var(--secondary-font);
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    margin-right: 3px;
    border-radius: 5px;
    line-height: 18px;
}

i.clickable {
    color: var(--secondary-font);
    cursor: pointer;
    border-radius: 5px;
}

.non-clickable-icon {
    color: var(--secondary-font);
    font-size: 14px;
    padding: 5px 5px 5px 0;
    margin-right: 3px;
    line-height: 18px;
}

.clickable-icon.active,
.clickable-icon:active,
i.clickable:active {
    background-color: var(--surface-color);
}

.close-icon {
    font-size: 23px;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.clickable-icon-dark:hover,
.clickable-icon:hover,
i.clickable:hover {
    color: var(--accent);
}

.rating-icon {
    transition:
        opacity 0.1s ease,
        background 0.1s ease,
        text-shadow 0.1s ease,
        color 0.1s ease;
}

.rating-icon:hover {
    color: #ffad17;
    transition:
        opacity 0.1s ease,
        background 0.1s ease,
        text-shadow 0.1s ease,
        color 0.1s ease;
}

.starred {
    color: #f2ca4b;
}

/* POPUP ICONS */
.folder-icon {
    color: var(--green);
}

.state-indicator {
    width: 8px;
    height: 8px;
    border-radius: 2px;
}
