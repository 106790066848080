[hidden],
.hidden {
    display: none !important;
}

.hide-native-scrollbar {
    scrollbar-width: none !important; /* Firefox 64 */
    -ms-overflow-style: none !important; /* Internet Explorer 11 */
    &::-webkit-scrollbar {
        /** WebKit */
        display: none !important;
    }
}

.overflow-scroll {
    overflow: scroll;
}

.rotate-270 {
    transform: rotate(270deg);
}

.width-fit-content {
    width: fit-content;
}

.max-width-fit-content {
    max-width: fit-content;
}

.fill-space {
    height: 100%;
    width: 100%;
    display: flex;
}

.i {
    font-style: italic;
}

.light,
.normal {
    font-weight: 400;
}

.bold {
    font-weight: 500;
}

.xbold {
    font-weight: 800;
}

.outline-0 {
    outline: 0;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.nowrap {
    white-space: nowrap;
}

.selectable-text {
    -webkit-user-select: text; /* Safari */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* IE10+/Edge */
    user-select: text; /* Standard */
}

.center-aligned {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-aligned {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 0 0;
    align-items: center;
}

.right-aligned {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 0 0;
    align-items: center;
    right: 0 !important;
}

.top-aligned {
    flex-direction: column;
    justify-content: flex-start;
}

.overflow-auto {
    overflow: auto;
}

.flex-l {
    display: flex;
}

.inline-flex-l {
    display: inline-flex;
}

.f1 {
    flex: 1;
}

.f0 {
    flex: 0;
}

.far {
    display: flex;
    flex-direction: column;
}

.fac {
    display: flex;
    flex-direction: row;
}

.flex-center-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-center-horizontal {
    display: flex;
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.top-0 {
    top: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.lh-22 {
    line-height: 22px;
}

.lh-24 {
    line-height: 24px;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

.self-baseline {
    align-self: baseline;
}

.self-stretch {
    align-self: stretch;
}

.tl {
    text-align: left;
}

.tr {
    text-align: right;
}

.tc {
    text-align: center;
}

.tj {
    text-align: justify;
}

.flexbox-grow {
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-height: 1px;
}

.flexbox-no-grow {
    -ms-flex-positive: 0;
    flex-grow: 0;
}

.flex {
    display: flex;
}
.block {
    display: block;
}

.inline-flex {
    display: inline-flex;
}

.flex-auto {
    min-height: 0;
    flex: 1 1 auto;
    min-width: 0;
}

.flex-none {
    flex: none;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-noshrink {
    flex-shrink: 0;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.justify-between {
    justify-content: space-between;
}

.actions-line {
    padding-bottom: 6px;
    display: block;
}

.scrollable-horizontal {
    min-width: 1px;
    overflow-x: auto;
}

.blur {
    filter: blur(3px);
}

.unselectable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.inline {
    display: inline;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}

.cursor-text {
    cursor: text;
}

.grab-cursor {
    cursor: grab;
}

.hover:hover {
    background-color: var(--surface-color);
}

$shadow: rgba(44, 44, 44, 0.27);
$shadow-light: rgba(44, 44, 44, 0.11);

.shadow-high {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.shadow-medium {
    box-shadow: 0 4px 14px -4px #919191;
}

.shadow-narrow {
    box-shadow: 0 1px 2px 0 $shadow;
}

.border-bottom {
    border-bottom: solid 1px var(--border-color);
}

.border-top {
    border-top: solid 1px var(--border-color);
}

.border-left {
    border-left: solid 1px var(--border-color);
}

.border-right {
    border-right: solid 1px var(--border-color);
}

.border {
    border: solid 1px var(--border-color);
}

.bn {
    border-style: none;
    border-width: 0;
}

.br0 {
    border-radius: 0px;
}

.br1 {
    border-radius: 1px;
}

.br2 {
    border-radius: 2px;
}

.br3 {
    border-radius: 3px;
}

.br4 {
    border-radius: 4px;
}

.br5 {
    border-radius: 8px;
}

.br-pills {
    border-radius: 9999px;
}

.inner-border {
    box-shadow: inset 0px 0px 0px 1px var(--border-color);
}

.inner-border-bottom {
    box-shadow: inset 0px -1px 0px 0px var(--border-color);
}

.inner-border-left {
    box-shadow: inset 1px 0px 0px 0px var(--border-color);
}

// font size
.fs1 {
    font-size: 3rem;
}

.fs2 {
    font-size: 2.25rem;
}

.fs3 {
    font-size: 1.5rem;
}

.fs4 {
    font-size: 1.25rem;
}

.fs45 {
    font-size: 1.125rem;
}

.fs5 {
    font-size: 1rem;
}

.fs6 {
    font-size: 0.875rem;
}

.fs7 {
    font-size: 0.75rem;
}

.fs8 {
    font-size: 11px;
}

// paddings
.pa0 {
    padding: 0;
}

.pa1 {
    padding: 0.25rem;
}

.pa2 {
    padding: 0.5rem;
}

.pa3 {
    padding: 1rem;
}

.pa4 {
    padding: 2rem;
}

.pa5 {
    padding: 4rem;
}

.pa6 {
    padding: 8rem;
}

.pa7 {
    padding: 16rem;
}

.pl0 {
    padding-left: 0;
}

.pl1 {
    padding-left: 0.25rem;
}

.pl2 {
    padding-left: 0.5rem;
}

.pl3 {
    padding-left: 1rem;
}

.pl4 {
    padding-left: 2rem;
}

.pl5 {
    padding-left: 4rem;
}

.pl6 {
    padding-left: 8rem;
}

.pl7 {
    padding-left: 16rem;
}

.pr0 {
    padding-right: 0;
}

.pr1 {
    padding-right: 0.25rem;
}

.pr2 {
    padding-right: 0.5rem;
}

.pr3 {
    padding-right: 1rem;
}

.pr4 {
    padding-right: 2rem;
}

.pr5 {
    padding-right: 4rem;
}

.pr6 {
    padding-right: 8rem;
}

.pr7 {
    padding-right: 16rem;
}

.pb0 {
    padding-bottom: 0;
}

.pb1 {
    padding-bottom: 0.25rem;
}

.pb2 {
    padding-bottom: 0.5rem;
}

.pb3 {
    padding-bottom: 1rem;
}

.pb4 {
    padding-bottom: 2rem;
}

.pb5 {
    padding-bottom: 4rem;
}

.pb6 {
    padding-bottom: 8rem;
}

.pb7 {
    padding-bottom: 16rem;
}

.pt0 {
    padding-top: 0;
}

.pt1 {
    padding-top: 0.25rem;
}

.pt2 {
    padding-top: 0.5rem;
}

.pt3 {
    padding-top: 1rem;
}

.pt4 {
    padding-top: 2rem;
}

.pt5 {
    padding-top: 4rem;
}

.pt6 {
    padding-top: 8rem;
}

.pt7 {
    padding-top: 16rem;
}

.pv0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pv1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.pv2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pv3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pv4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.pv5 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.pv6 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.pv7 {
    padding-top: 16rem;
    padding-bottom: 16rem;
}

.ph0 {
    padding-left: 0;
    padding-right: 0;
}

.ph1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.ph2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ph3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.ph4 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.ph5 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.ph6 {
    padding-left: 8rem;
    padding-right: 8rem;
}

.ph7 {
    padding-left: 16rem;
    padding-right: 16rem;
}

// margins
.ma0 {
    margin: 0;
}

.ma1 {
    margin: 0.25rem;
}

.ma2 {
    margin: 0.5rem;
}

.ma3 {
    margin: 1rem;
}

.ma4 {
    margin: 2rem;
}

.ma5 {
    margin: 4rem;
}

.ma6 {
    margin: 8rem;
}

.ma7 {
    margin: 16rem;
}

.ml0 {
    margin-left: 0;
}

.ml1 {
    margin-left: 0.25rem;
}

.ml2 {
    margin-left: 0.5rem;
}

.ml3 {
    margin-left: 1rem;
}

.ml4 {
    margin-left: 2rem;
}

.ml5 {
    margin-left: 4rem;
}

.ml6 {
    margin-left: 8rem;
}

.ml7 {
    margin-left: 16rem;
}

.mr0 {
    margin-right: 0;
}

.mr1 {
    margin-right: 0.25rem;
}

.mr2 {
    margin-right: 0.5rem;
}

.mr3 {
    margin-right: 1rem;
}

.mr4 {
    margin-right: 2rem;
}

.mr5 {
    margin-right: 4rem;
}

.mr6 {
    margin-right: 8rem;
}

.mr7 {
    margin-right: 16rem;
}

.mb0 {
    margin-bottom: 0;
}

.mb1 {
    margin-bottom: 0.25rem;
}

.mb2 {
    margin-bottom: 0.5rem;
}

.mb3 {
    margin-bottom: 1rem;
}

.mb4 {
    margin-bottom: 2rem;
}

.mb5 {
    margin-bottom: 4rem;
}

.mb6 {
    margin-bottom: 8rem;
}

.mb7 {
    margin-bottom: 16rem;
}

.mt0 {
    margin-top: 0;
}

.mt1 {
    margin-top: 0.25rem;
}

.mt2 {
    margin-top: 0.5rem;
}

.mt3 {
    margin-top: 1rem;
}

.mt4 {
    margin-top: 2rem;
}

.mt5 {
    margin-top: 4rem;
}

.mt6 {
    margin-top: 8rem;
}

.mt7 {
    margin-top: 16rem;
}

.mv0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mv1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.mv2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mv3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mv4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mv5 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.mv6 {
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.mv7 {
    margin-top: 16rem;
    margin-bottom: 16rem;
}

.mh0 {
    margin-left: 0;
    margin-right: 0;
}

.mh1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.mh2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.mh3 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mh4 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.mh5 {
    margin-left: 4rem;
    margin-right: 4rem;
}

.mh6 {
    margin-left: 8rem;
    margin-right: 8rem;
}

.mh7 {
    margin-left: 16rem;
    margin-right: 16rem;
}

.w1 {
    width: 1rem;
}

.w2 {
    width: 2rem;
}

.w3 {
    width: 4rem;
}

.w4 {
    width: 8rem;
}

.w5 {
    width: 16rem;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-33 {
    width: 33%;
}

.w-34 {
    width: 34%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

.w-third {
    width: calc(100% / 3);
}

.w-two-thirds {
    width: calc(100% / 1.5);
}

.w-auto {
    width: auto;
}

//dimensions
.mw-100 {
    max-width: 100%;
}

.mw1 {
    max-width: 1rem;
}

.mw2 {
    max-width: 2rem;
}

.mw3 {
    max-width: 4rem;
}

.mw4 {
    max-width: 8rem;
}

.mw45 {
    max-width: 12rem;
}

.mw5 {
    max-width: 16rem;
}

.mw6 {
    max-width: 32rem;
}

.mw7 {
    max-width: 48rem;
}

.mw8 {
    max-width: 64rem;
}

.mw9 {
    max-width: 96rem;
}

.mw-none {
    max-width: none;
}

.miw0 {
    min-width: 0rem;
}

.miw1 {
    min-width: 1rem;
}

.miw2 {
    min-width: 2rem;
}

.miw3 {
    min-width: 4rem;
}

.miw4 {
    min-width: 8rem;
}

.miw5 {
    min-width: 16rem;
}

.miw6 {
    min-width: 32rem;
}

.miw7 {
    min-width: 48rem;
}

.miw8 {
    min-width: 64rem;
}

.miw9 {
    min-width: 96rem;
}

.mih1 {
    min-height: 1rem;
}

.mih2 {
    min-height: 2rem;
}

.mih3 {
    min-height: 4rem;
}

.mih4 {
    min-height: 8rem;
}

.mih5 {
    min-height: 16rem;
}

.mih6 {
    min-height: 32rem;
}

.mih7 {
    min-height: 48rem;
}

.mih8 {
    min-height: 64rem;
}

.mih9 {
    min-height: 96rem;
}
// text align
.tl {
    text-align: left;
}

.tr {
    text-align: right;
}

.tc {
    text-align: center;
}

.tj {
    text-align: justify;
}

// height

.h1 {
    height: 1rem;
}

.h2 {
    height: 2rem;
}

.h3 {
    height: 4rem;
}

.h4 {
    height: 8rem;
}

.h5 {
    height: 16rem;
}

.h-25 {
    height: 25%;
}

.h-50 {
    height: 50%;
}

.h-75 {
    height: 75%;
}

.h-100 {
    height: 100%;
}

// displays
.dib {
    display: inline-block;
}

// text transform
.ttc {
    text-transform: capitalize;
}

.ttl {
    text-transform: lowercase;
}

.ttu {
    text-transform: uppercase;
}

.hiddenOnHover-parent {
    .hiddenOnHover-element {
        visibility: hidden;
    }
    &:hover {
        .hiddenOnHover-element {
            visibility: visible;
        }
    }
}

.hiddenOnFocusWithin-parent {
    .hiddenOnFocusWithin-element {
        visibility: hidden;
    }
    &:focus-within {
        .hiddenOnFocusWithin-element {
            visibility: visible;
        }
    }
}
