.onboarding {
    .form-container {
        padding: 32px 0px 0px 0px;
        .element {
            padding: 8px 0px;
            .label,
            .description,
            .value {
                padding: 8px 0px;
            }
            .description {
                &:not(.primary) {
                    color: var(--secondary-font);
                }
                .accent {
                    color: var(--yellow-text);
                }
            }
            .label {
                font-weight: 500;
                font-size: 14px;
                .accent {
                    color: var(--blue-text);
                }
            }
            .value {
                display: flex;
                flex-direction: row;
            }
        }
    }
}
