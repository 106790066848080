button:disabled {
    background-color: #eee;
    color: #aaa;
    cursor: auto;
}

.h-button {
    &.disabled,
    &[disabled="true"],
    &:disabled {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        box-shadow: none;
        opacity: 0.65;
    }

    &.small {
        height: 28px;
        padding: 0px 8px;
    }

    &.xsmall {
        height: 24px;
        overflow: hidden;
        padding: 0px 6px;
        border-radius: 3px;
    }

    &.smaller {
        height: 20px;
        overflow: hidden;
        padding: 0px 4px;
        border-radius: 3px;
        font-size: 12px;
    }

    width: fit-content;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    height: 32px;
    overflow: hidden;
    padding: 0px 12px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    background-color: var(--surface-color);
    color: var(--primary-font);
    fill: var(--primary-font);
    align-items: center;

    .text {
        padding-right: 4px;
        padding-left: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }

    &:hover {
        background-color: var(--border-color);
        background-image: none;
        box-shadow:
            0 0 0 1px transparent inset,
            0 0 0 0 rgba(34, 36, 38, 0.15) inset;
        color: rgba(0, 0, 0, 0.8);
    }

    & + & {
        margin-left: 0.25rem;
    }
}

.sidebar-square-button {
    height: 28px;
    width: 28px;
    border-radius: 6px;
    margin-left: 8px;
    color: var(--primary-font);
    font-size: 14px;
    cursor: pointer;
}

.sidebar-square-button i[class|="icon"] {
    margin: 0px;
}

.sidebar-square-button:hover {
    background-color: var(--border-color);
}

.h-icon-button {
    width: 34px;
    padding: 0px;
}

.secondary-button {
    &:not(.smaller):not(.xsmall) {
        background: none;
        color: var(--accent);
        border: 1px solid var(--accent);
        margin-left: -1px;
        &:hover {
            background-color: var(--surface-color);
        }
    }

    &.smaller,
    &.xsmall {
        background: none;
        color: var(--primary-font);
        border: 1px solid var(--border-color);
        margin-left: -1px;

        i {
            color: var(--secondary-font);
        }

        &:hover {
            background-color: var(--surface-color);
        }
    }
}

.danger-button {
    color: #ef3426;
    background: white;
    border: 1px solid;

    &:hover {
        color: white;
        background: #ef3426;
        border-color: #ef3426;
        border: 1px solid;
    }
}

.transparent-button {
    color: var(--secondary-font);
    background-color: transparent;

    &:hover {
        background-color: var(--surface-color);
    }
}

.accent-button {
    &:not(.smaller):not(.xsmall) {
        background-color: var(--accent);
        color: var(--white);

        &:not(.noshadow) {
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.14901960784313725);
        }

        &:hover {
            background-color: var(--accent-hover) !important;
            color: var(--white) !important;
            background-image: none;

            &:not(.noshadow) {
                box-shadow:
                    0 0 0 1px transparent inset,
                    0 0 0 0 rgba(34, 36, 38, 0.15) inset;
            }

            border-color: var(--border-color);
        }
    }

    &.smaller,
    &.xsmall {
        background: none;
        color: var(--primary-font);
        background-color: var(--white);
        border: 1px solid var(--border-color);
        margin-left: -1px;

        i {
            color: var(--accent);
        }

        &:hover {
            background-color: var(--surface-color);
        }
    }
}

.light-button {
    outline: none;
    background: none;
    color: var(--accent);
    border: none;
    margin-left: -1px;

    &:hover {
        background-color: var(--surface-color);
    }
}

.third-button {
    background-color: var(--surface-color);
    color: var(--primary-font);

    &:hover {
        background-color: var(--border-color);
        background-image: none;
        box-shadow:
            0 0 0 1px transparent inset,
            0 0 0 0 rgba(34, 36, 38, 0.15) inset;
        color: var(--primary-font);
    }
}

.load-more-button {
    font-weight: 500;
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
    height: 51px;
    font-size: 13px;
    width: 100%;
}

.h-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 22px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-right: 8px;
    height: 32px;
    &.mat-mdc-select {
        display: flex;
    }
    &.small {
        height: 28px;
    }
    padding: 0px 8px;
    .text {
        padding-right: 4px;
        padding-left: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    &:hover {
        background: var(--surface-color);
    }
}

.h-double-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-right: 8px;
    height: 32px;
    float: left;
    position: relative;
    border: 0;

    .left,
    .right {
        border: none;
        height: inherit;
        line-height: 100%;
        cursor: pointer;
    }

    .left {
        border-radius: 3px 0 0 3px;
    }

    .right {
        border-radius: 0 3px 3px 0;
        border-left: solid 1px #76a5d8;
    }
}

.h-toggle-link {
    @extend .h-toggle;
    color: var(--accent);
    font-weight: 500;
}

.accent-toggle {
    background-color: var(--surface-color);
    color: var(--primary-font);
}

%h-toggle-solo {
    @extend .h-toggle;
    color: var(--primary-font);
    font-size: 16px; // border-radius: 3px;
    // margin-right: 8px;
    // padding: 0px 5px;
    // height: 28px;
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.link {
    text-decoration: underline;
    cursor: pointer;
    color: var(--accent);
    line-height: 30px;
    margin: 3px 0px;
    display: flex;
    padding: 0px 6px;
    border-radius: 4px;
    width: fit-content;

    &:hover {
        background: var(--surface-color);
    }
}

.toggle-arrow {
    height: 24px;
    padding: 0px 4px 0 4px;
    display: flex;
    flex: 1;
}

.round-floating-toggle {
    position: absolute;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    box-shadow: 1px 1px 6px #0000003b;
    cursor: pointer;

    &:hover {
        background-color: var(--surface-color);
    }
}

.vertical-button {
    margin-bottom: 8px;
    width: 100%;
}

.huge-button {
    width: 100%;
    text-align: center;
    display: block;
    height: 42px;
}

.fluid-button {
    width: 100%;
    text-align: center;
    display: block;
}

.huge-button.accent-button {
    box-shadow: 0px 1px 3px 0px #00000069;
}

.g-signin2 {
    .abcRioButton {
        width: 100% !important;
    }
}

.mat-button-toggle {
    --mat-toggle-height: 32px;
    outline: none;
    .mat-button-toggle-label-content {
        line-height: var(--mat-toggle-height) !important;
        height: var(--mat-toggle-height) !important;
    }
    &.mat-button-toggle-checked {
        background-color: transparent !important;
        color: var(--accent) !important;
        box-shadow: inset 0px 0px 0px 1px var(--accent);
    }
    &.input-toggle {
        .mat-button-toggle-label-content {
            padding: 0px;
            input {
                padding-left: 8px;
                background: transparent;
                outline: none;
                border: none;
                height: 100%;
                line-height: var(--mat-toggle-height);
                height: var(--mat-toggle-height);
            }
        }
    }
}
