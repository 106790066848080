.breadcrumb-container {
    &.clickable {
        border-radius: 5px;
        &:hover {
            background-color: var(--surface-color);
        }
    }
    display: inline-flex;
    .breadcrumb-element,
    .breadcrumb-delimiter {
        line-height: 28px;
        height: 28px;
        display: inline-flex;
    }
    .breadcrumb-element {
        padding: 0 6px;
        margin: 0 2px;
        // max-width: 240px;
        max-width: 400px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 4px;
        &:hover {
            background-color: var(--surface-color);
        }
    }
}

.discovery-title-textarea {
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    text-overflow: ellipsis;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background: none;
    border: none;
    outline: none;
    &:focus {
        outline: none;
    }
    resize: none;
    margin: 0px;
    overflow: hidden;
    word-wrap: break-word;
    line-height: 28px;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
}
