@font-face {
    font-family: "hx-icons";
    src: url("../../assets/fonts/hx-icons.eot?22fi1w");
    src:
        url("../../assets/fonts/hx-icons.eot?22fi1w#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/hx-icons.ttf?22fi1w") format("truetype"),
        url("../../assets/fonts/hx-icons.woff?22fi1w") format("woff"),
        url("../../assets/fonts/hx-icons.svg?22fi1w#hx-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="iconhx-"],
[class*=" iconhx-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "hx-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconhx-figma:before {
    content: "\b91bf";
}
.iconhx-chrome:before {
    content: "\bafd9";
}
.iconhx-salesforce:before {
    content: "\54dfb";
}
.iconhx-jira:before {
    content: "\f91df";
}
.iconhx-slack:before {
    content: "\f91eb";
}
.iconhx-freshdesk:before {
    content: "\b91ff";
}
.iconhx-harvestr--black:before {
    content: "\f900f";
}
.iconhx-harvestr:before {
    content: "\f901f";
}
.iconhx-hubspot:before {
    content: "\f906f";
}
.iconhx-relation:before {
    content: "\fb82b";
}
.iconhx-merge-duplicates:before {
    content: "\f90db";
}
.iconhx-feedback:before {
    content: "\ff4e2";
}
.iconhx-arrow-down:before {
    content: "\25beb";
}
.iconhx-arrow-right:before {
    content: "\f5b8b";
}
.iconhx-multiselect:before {
    content: "\b90cb";
}
.iconhx-flask:before {
    content: "\bf6e0";
}
.iconhx-sub-discovery:before {
    content: "\2b91b";
}
.iconhx-table:before {
    content: "\e910b";
}
.iconhx-kanban:before {
    content: "\eb0fb";
}
.iconhx-column:before {
    content: "\e911b";
}
.iconhx-csv-import:before {
    content: "\bf58b";
}
.iconhx-unread:before {
    content: "\bf4e9";
}
.iconhx-smile-add:before {
    content: "\bf64b";
}
.iconhx-rightbar:before {
    content: "\2348b";
}
.iconhx-dragger:before {
    content: "\e916b";
}
.iconhx-dropdown:before {
    content: "\25bfb";
}
.iconhx-puzzle:before {
    content: "\e918b";
}
.iconhx-triple-circle:before {
    content: "\e919b";
}
.iconhx-square-filled:before {
    content: "\ea27b";
}
