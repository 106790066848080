hx-sidebar {
    .sidebar-hover-actions {
        visibility: hidden;
        width: 0px;
        &.visible {
            visibility: visible;
            width: fit-content;
        }
    }
    &:hover {
        .sidebar-hover-actions {
            visibility: visible;
            width: fit-content;
        }
    }
}

.hx-sidebar-menu-line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: inherit;
    &.active,
    &.selected {
        background: var(--border-color);
        color: var(--accent);
        .text {
            font-weight: 500;
        }
    }
    &:hover {
        background: var(--border-color);
    }

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    outline: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 3px;
    padding: 2px 0.5rem;
    font-weight: 400;
    height: 30px;
    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
        vertical-align: middle;
        white-space: nowrap;
        line-height: 30px;
        flex: 1 auto;
    }
}

.hx-sidebar-menu-line {
    .hover-actions {
        visibility: hidden;
        width: 0px;
        &.visible {
            visibility: visible;
            width: fit-content;
        }
    }
    &:hover {
        .hover-actions {
            visibility: visible;
            width: fit-content;
        }
    }
}

.custom-drag-ghost > .hx-sidebar-menu-line {
    .hover-actions {
        visibility: visible;
        width: fit-content;
    }
}

.hx-sidebar-icon {
    margin: 0 12px 0 2px;
    font-size: 14px;
}
