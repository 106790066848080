.chunk-circle-score {
    --circle-selector-diameter: 28px;
    &.small {
        --circle-selector-diameter: 24px;
        font-size: 12px;
        color: var(--secondary-font);
    }
    margin-right: 8px;
    border-radius: 999px;
    height: var(--circle-selector-diameter);
    min-width: var(--circle-selector-diameter);
    border: solid 1px var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    &.selectable {
        cursor: pointer;
        &:not(.selected):hover {
            background-color: var(--surface-color);
        }
    }
    &.custom {
        width: fit-content;
    }
    &.selected {
        background-color: var(--selected);
        border-color: var(--blue);
        color: var(--blue);
    }
    .custom-score {
        cursor: pointer;
        border-radius: 999px;
        padding: 0px 9px !important;
        height: var(--circle-selector-diameter);
        min-height: fit-content;
        margin: 0px;
        border: none;
        background-color: transparent;
        outline: none;
        -moz-appearance: textfield;
        width: 68px;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
