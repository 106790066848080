.content-tabs-holder {
    position: relative;
    display: inline-flex;
    &:not(.mb0) {
        margin-bottom: 6px;
    }
    width: 100%;
    &:not(.noborder) {
        border-bottom: 1px solid var(--border-color);
    }
    // * scroll content without bar
    &:not(.noscroll) {
        overflow-x: auto;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &.detached {
        position: sticky;
        top: 0;
        background: inherit;
        z-index: 999;
        background: var(--main-background);
    }
    .content-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 30px;
        color: var(--secondary-font);
        padding: 0px 16px;
        cursor: pointer;
        white-space: nowrap;
        // background: inherit;
        &.no-padding {
            padding: 0px;
        }
        &:not(.selected):hover {
            color: var(--primary-font);
        }
        &.selected {
            font-weight: 500;
            color: var(--primary-font);
            border-bottom: solid 2px var(--accent);
            cursor: default;
        }
    }
    &.tall {
        .content-tab {
            line-height: 34px;
        }
    }
}

.tab {
    cursor: pointer;
    &:hover {
        color: var(--primary-font);
    }
    &.active {
        font-weight: 500;
        color: var(--primary-font);
        border-bottom: solid 2px var(--accent);
        cursor: default;
    }
}
