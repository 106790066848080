:root {
    --hx-input-radius-px: 4px;
    --hx-button-radius-px: 5px;
    --hx-menu-radius-px: 6px;
    --hx-dialog-radius-px: 8px;
    --hx-card-radius-px: 6px;
    --hx-tag-radius-px: 4px;
    --hx-tag-bubble-radius-px: 6px;

    --scrollbarThumbHoverColor: var(--secondary-font);
    --scrollbarWidth: 6px;
}

:root {
    --root-azure: #05c7c6;
    --root-blue: #42b2fc;
    --root-lblue: #6be0e7;
    --root-green: #5bc692;
    --root-lgreen: #98c141;
    --root-lyellow: #c7bb44;
    --root-yellow: #fdbd53;
    --root-orange: #fd8b4d;
    --root-red: #f96c68;
    --root-grey: #b0b0b0;
    --root-lpurple: #7856ff;
    --root-purple: #a64dff;
    --root-lpink: #d83dfe;
    --root-pink: #ff2fb9;
    --root-white: #ffffff;
    --root-black: #323232;
    --shortcut-dark-transparent: rgba(167, 167, 167, 0.5099999904632568);
    --shortcut-light-transparent: rgba(255, 255, 255, 0.5099999904632568);
    --shortcut-disabled-transparent: rgba(220, 220, 220, 0.5099999904632568);
    --error: #f5544f;
    --error-hover: #f96c68;
    --preview-mode-a: #edbc0b;
    --preview-mode-b: #915f2f;
    --preview-mode-c: #fff6d8;
    --input-outline-color: #5e9ed6;
    --cell-active-background: rgb(219 241 255 / 85%);
    --cell-active-border: rgb(88 144 255 / 40%);

    // --summarize-gradient: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
    --summarize-gradient: linear-gradient(
        98deg,
        #a64dff 43.47%,
        #7856ff 106.71%
    );
    --summarize-gradient-light: linear-gradient(
        90deg,
        #8577dc 0%,
        #ca7bd7 100%
    );
    --summarize-gradient-light-20: linear-gradient(
        90deg,
        #7363d9 0%,
        #c966d8 100%
    );
    --red-gradient: linear-gradient(98deg, #ff8049 43.47%, #f7513a 106.71%);
    --blue-gradient: linear-gradient(98deg, #42b2fc 43.47%, #05c7c6 106.71%);

    --search-highlight: #ffc;

    --azure: var(--root-azure);
    --blue: var(--root-blue);
    --lblue: var(--root-lblue);
    --green: var(--root-green);
    --lgreen: var(--root-lgreen);
    --lyellow: var(--root-lyellow);
    --yellow: var(--root-yellow);
    --orange: var(--root-orange);
    --red: var(--root-red);
    --grey: var(--root-grey);
    --lpurple: var(--root-lpurple);
    --purple: var(--root-purple);
    --lpink: var(--root-lpink);
    --pink: var(--root-pink);
    --white: var(--root-white);
    --black: var(--root-black);
}

// ? light theme
:root {
    --accent: rgba(0, 115, 239, 1);
    --accent-hover: #005dc1;
    --accent-opposite: rgba(255, 255, 255, 1);
    --primary-font: rgba(68, 68, 68, 1);
    --secondary-font: rgba(176, 176, 176, 1);
    --secondary-accent-font: #858585;
    --border-color: rgba(238, 238, 238, 1);
    --border-accent: rgb(225, 225, 225);
    --surface-color: rgba(246, 246, 246, 1);
    --main-background: rgba(255, 255, 255, 1);
    --menu-background: rgba(255, 255, 255, 1);
    --menu-light-background: rgba(246, 246, 246, 1);
    --modale-border: rgba(255, 255, 255, 1);
    --scrollbar-color: rgba(216, 216, 216, 1);
    --opaque-surface-color: rgba(246, 246, 246, 1);
    --blue-10: rgba(230, 245, 255, 1);
    --lblue-10: rgba(233, 250, 251, 1);
    --green-10: rgba(234, 251, 246, 1);
    --yellow-10: rgba(255, 243, 224, 1);
    --orange-10: rgba(255, 239, 230, 1);
    --orange-20: rgb(255, 220, 199);
    --purple-10: rgba(246, 237, 255, 1);
    --red-10: rgba(254, 231, 230, 1);
    --azure-10: rgba(230, 249, 249, 1);
    --lyellow-10: rgba(250, 248, 233, 1);
    --pink-10: rgba(255, 232, 247, 1);
    --lpink-10: rgba(251, 236, 255, 1);
    --lpurple-10: rgba(242, 238, 255, 1);
    --lgreen-10: rgba(243, 249, 232, 1);

    --yellow-20: #feeccd;
    --red-20: #fdcfce;
    --green-20: #d7faf0;
    --blue-20: #cdebfe;
    --lblue-20: #d3f6f8;
    --orange-20: #fedecd;
    --purple-20: rgb(235 214 255);

    --blue-text: rgba(66, 178, 252, 1);
    --lblue-text: rgba(107, 224, 231, 1);
    --green-text: rgba(91, 198, 146, 1);
    --yellow-text: rgba(253, 189, 83, 1);
    --orange-text: rgba(253, 139, 77, 1);
    --red-text: rgba(249, 108, 104, 1);
    --purple-text: rgba(166, 77, 255, 1);
    --lpurple-text: rgba(120, 86, 255, 1);
    --pink-text: rgba(255, 47, 185, 1);
    --lpink-text: rgba(216, 61, 254, 1);
    --azure-text: rgba(5, 199, 198, 1);
    --lyellow-text: rgba(199, 187, 68, 1);
    --lgreen-text: rgba(152, 193, 65, 1);

    --blue-60: #0489e2;
    --red-60: #dd0f09;
    --orange-60: #e35203;
    --lblue-60: #20bcc5;
    --green-60: #19af82;
    --yellow-60: #e38e03;

    --yellow-70: #6d4300;
    --red-70: #840400;
    --green-70: #0e644a;

    --lblue-grey: #f7f9fa; // legacy, do not use
    --selected: #e8f5ff; // legacy, do not use

    --preview-mode-a: #edbc0b;
    --preview-mode-b: #915f2f;
    --preview-mode-c: transparent; //#fff6d8;

    --dialog-background: var(--main-background);
    --rdialog--body-background: var(--main-background);
    --roadmap--scrollbar-track: rgba(0, 0, 0, 0.08);
    --roadmap--scrollbar-thumb: rgba(0, 0, 0, 0.25);

    --generic-hover: #fbfbfb;
    --discoverycell-hover: #f3f3f3;
    --messagecard-hover: #fbfbfb;
    --messagecard-selected: #e8f3ff;
    --messagecard-selected-border: #cae4ff;
    --messagecard-unread-color: #929292;
    --snippet-color: #94deff;
    --snippet-hover: #5dcdff;

    --rating-remove: #81ccfd;
    --rating-add: #0489e2;

    --scrollbarThumbColor: var(--secondary-font);
    --scrollbarColor: var(--surface-color);
    --scrollbarThumbHoverColor: var(--secondary-font);
}

// ? dark theme
[data-theme="dark"] {
    --accent: rgba(136, 106, 255, 1);
    --accent-hover: #4832a0;
    --accent-opposite: rgba(244, 241, 255, 1);
    --primary-font: rgba(208, 208, 208, 1);
    --secondary-font: rgba(137, 137, 137, 1);
    --secondary-accent-font: var(--secondary-font);
    --border-color: rgba(54, 54, 54, 1);
    --border-accent: rgb(70, 70, 70);
    --surface-color: rgba(50, 50, 50, 1);
    --opaque-surface-color: rgba(35, 35, 35, 1);
    --main-background: rgba(18, 18, 18, 1);
    --menu-background: rgba(35, 35, 35, 1);
    --menu-light-background: rgba(44, 44, 44, 1);
    --modale-border: rgba(50, 50, 50, 1);
    --scrollbar-color: rgba(109, 109, 109, 1);
    --orange-20: rgba(61, 40, 29, 1);
    --blue-10: rgba(9, 57, 89, 1);
    --lblue-10: rgba(9, 73, 77, 1);
    --green-10: rgba(4, 61, 44, 1);
    --yellow-10: rgba(66, 49, 21, 1);
    --orange-10: rgba(73, 31, 7, 1);
    --red-10: rgba(81, 12, 9, 1);
    --purple-10: rgba(44, 24, 64, 1);
    --lpurple-10: rgba(35, 27, 60, 1);
    --pink-10: rgba(73, 12, 52, 1);
    --lpink-10: rgba(80, 19, 97, 1);
    --azure-10: rgba(15, 77, 77, 1);
    --lyellow-10: rgba(91, 82, 8, 1);
    --lgreen-10: rgba(47, 73, 0, 1);
    --blue-text: rgba(67, 255, 244, 1);
    --lblue-text: rgba(174, 250, 255, 1);
    --green-text: rgba(111, 252, 183, 1);
    --yellow-text: rgba(255, 244, 146, 1);
    --orange-text: rgba(255, 191, 156, 1);
    --red-text: rgba(255, 157, 154, 1);
    --purple-text: rgba(208, 161, 255, 1);
    --lpurple-text: rgba(180, 161, 255, 1);
    --pink-text: rgba(255, 130, 213, 1);
    --azure-text: rgba(70, 253, 252, 1);
    --lyellow-text: rgba(255, 241, 101, 1);
    --lgreen-text: rgba(197, 241, 104, 1);
    --lpink-text: rgba(235, 153, 255, 1);
    --preview-mode-a: #edbc0b;
    --preview-mode-b: #915f2f;
    --preview-mode-c: transparent; //#fff6d8;

    --dialog-background: var(--main-background);
    --rdialog--body-background: var(--main-background);
    --roadmap--scrollbar-track: rgba(255, 255, 255, 0.08);
    --roadmap--scrollbar-thumb: rgba(255, 255, 255, 0.25);
    --roadmap--card-primary-font: var(--primary-font);
    --roadmap--card-secondary-font: var(--secondary-font);

    --scrollbarThumbColor: var(--secondary-font);
    --scrollbarColor: var(--surface-color);
    --scrollbarThumbHoverColor: var(--secondary-font);
}

:root {
    --font-family: "Inter", BlinkMacSystemFont, Ubuntu, "Open Sans",
        "SF Pro Display", -apple-system, "Segoe UI", Roboto, Oxygen, Cantarell,
        "Helvetica Neue", sans-serif;
    --progressbar-background: var(--surface-color);
    --progressbar-started: var(--orange-20);
    --progressbar-done: var(--orange);
}

// ? ****** Roadmap
:root {
    --roadmap--font-family: var(--font-family);
    --roadmap--background: var(--main-background);
    --roadmap--background-image-position: center center;
    --roadmap--background-image-fit: contain;

    --roadmap--banner-background: rgb(68, 68, 68);
    --roadmap--banner-primary-font: white;
    --roadmap--banner-secondary-font: #c0c0c0;

    --roadmap--column-background: transparent;
    --roadmap--column-border-color: var(--roadmap--column-background);
    --roadmap--column-border-radius: 6px;
    --roadmap--column-header-padding: 8px 20px;
    --roadmap--column-collapsed-border-color: var(--border-color);
    --roadmap--column-collapsed-background: var(--roadmap--column-background);
    --roadmap--column-header-font-weight: 600;
    --roadmap--column-header-align-items: left;

    --roadmap--subgroup-background: var(--surface-color);
    --roadmap--subgroup-border-radius: 8px;
    --roadmap--subgroup-border-color: var(--border-color);
    // --roadmap--subgroup-box-shadow: 1px 1px 4px rgb(0 0 0 / 7%);
    --roadmap--subgroup-box-shadow: none;
    --roadmap-subgroup-sticky-header-border-color: transparent;
    --roadmap-subgroup-sticky-header-background: var(--surface-color);

    --roadmap--card-background: var(--menu-background);
    // --roadmap--card-border-radius: 4px;
    --roadmap--card-border-radius: 8px;
    --roadmap--card-border-style: solid;
    --roadmap--card-border-color: var(--border-color);
    --roadmap--card--hover-background: var(--main-background);
    --roadmap--card--hover-border-color: var(--border-accent);
    --roadmap--card--hover-box-shadow: 0 10px 14px -4px rgb(81 108 179 / 16%);
    // --roadmap--card-box-shadow: none;
    --roadmap--card-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    --roadmap--card-caption-font-size: 13px;
    // --roadmap--card-tumbnail-margin: 10px 10px 0px 10px;
    // --roadmap--card-tumbnail-border-radius: 4px;
    --roadmap--card-tumbnail-margin: 0px;
    --roadmap--card-tumbnail-border-radius: 0px;
    --roadmap--card-primary-font: var(--primary-font);
    --roadmap--card-secondary-font: var(--secondary-font);

    --rdialog--title-font-weight: 600;
    --rdialog--header-border-color: transparent;
    --rdialog--footer-background: var(--surface-color);
    --rdialog--cover-border-radius: 6px;
}

[roadmap-theme="default"] {
    --roadmap--background: var(--main-background);
    --roadmap--column-border-color: var(--roadmap--column-background);
    --roadmap--column-collapsed-border-color: var(--border-color);
    --roadmap--column-collapsed-background: var(--roadmap--column-background);
    --roadmap--subgroup-background: var(--surface-color);
    --roadmap--subgroup-border-color: var(--border-color);
    --roadmap-subgroup-sticky-header-background: var(--surface-color);
    --roadmap--card-background: var(--menu-background);
    --roadmap--card-border-color: var(--border-color);
    --roadmap--card--hover-background: var(--main-background);
    --roadmap--card--hover-border-color: var(--border-accent);
    --rdialog--footer-background: var(--surface-color);
}

[roadmap-theme="color"] {
    // * Light color
    &:not([data-theme="dark"]) {
        --surface-color: rgba(0, 0, 0, 0.15);
    }
    // * Dark color
    &[data-theme="dark"] {
        --surface-color: rgba(255, 255, 255, 0.15);
    }

    --roadmap--column-collapsed-border-color: rgba(
        var(--roadmap--card-background-rgb),
        0.3
    );
    --roadmap--subgroup-border-color: rgba(
        var(--roadmap--card-background-rgb),
        0.3
    );
    --roadmap--column-collapsed-background: var(--roadmap--subgroup-background);

    --roadmap--card-background-rgb: 197, 196, 255;

    // --border-color: rgba(var(--roadmap--card-background-rgb), 0.3);

    --roadmap--card-background: rgba(var(--roadmap--card-background-rgb), 0.15);
    --roadmap--card--hover-background: rgba(
        var(--roadmap--card-background-rgb),
        0.25
    );

    --roadmap--subgroup-background: transparent;
    --roadmap-subgroup-sticky-header-background: var(--roadmap--background);
    // --roadmap-subgroup-sticky-header-background: var(--surface-color);

    --roadmap--card-border-style: none;
    --roadmap--card-border-color: var(--border-color);
    --roadmap--card--hover-border-color: var(--border-accent);
    --roadmap--card--hover-box-shadow: 0 10px 14px -4px rgb(81 108 179 / 16%);

    --rdialog--footer-background: var(--surface-color);

    --progressbar-background: #f8f8fa;
    --progressbar-started: #67dae1;
    --progressbar-done: #23bac4;
}

//* dialogs
.hx-mat-dialog {
    // * app dialogs
    &:not(.dialog-roadmap-project) .mat-dialog-container,
    &:not(.dialog-roadmap-project) .mat-mdc-dialog-container,
    &:not(.dialog-roadmap-project) .cdk-dialog-container {
        background: var(--dialog-background);
        .hx-dialog-content,
        .cdk-dialog-content {
            background: var(--dialog-background);
        }
    }
    // * roadmap project dialog
    &.dialog-roadmap-project .mat-dialog-container,
    &.dialog-roadmap-project .mat-mdc-dialog-container,
    &.dialog-roadmap-project .cdk-dialog-container {
        background: var(--rdialog--body-background);
        .hx-dialog-content,
        .cdk-dialog-content {
            background: transparent;
        }
        .reactions-cta-container {
            background: var(--rdialog--footer-background);
        }
    }
}

// ? material variables overload
:root {
    --mdc-checkbox-state-layer-size: 32px;

    --mat-menu-item-leading-spacing: 16px;
    --mat-menu-item-trailing-spacing: 16px;

    --mat-menu-item-hover-state-layer-color: var(--surface-color);
    --mat-menu-item-focus-state-layer-color: var(--surface-color);
    --mat-menu-container-color: var(--menu-background);
    --mat-menu-divider-color: var(--border-color);

    --mat-menu-item-label-text-line-height: null;
    --mat-menu-item-label-text-size: null;
    --mat-menu-item-label-text-tracking: null;
    --mat-menu-item-label-text-weight: null;
    --mat-menu-item-label-text-color: null;
    --mat-tree-node-text-color: null;
    --mat-tree-node-text-size: null;
    --mat-tree-node-text-weight: null;

    --mat-standard-button-toggle-shape: 4px;
    --mat-standard-button-toggle-divider-color: var(--border-accent);
    --mat-standard-button-toggle-label-text-weight: 400;
    --mat-standard-button-toggle-state-layer-color: var(--root-grey);

    --mat-option-selected-state-label-text-color: var(--primary-font);
    --mat-option-label-text-color: var(--primary-font);
    --mat-ripple-color: rgba(28, 28, 23, 0.1);
    --mat-option-hover-state-layer-color: rgba(28, 28, 23, 0.08);
    --mat-option-focus-state-layer-color: rgba(28, 28, 23, 0.12);
    --mat-option-selected-state-layer-color: transparent;

    --mat-option-label-text-line-height: 1.25rem;
    --mat-option-label-text-size: 14px;
    --mat-option-label-text-tracking: normal;
    --mat-option-label-text-weight: 400;
    --mat-optgroup-label-text-color: var(--primary-font);
    --mat-select-enabled-trigger-text-color: var(--primary-font);

    --mat-optgroup-label-text-size: 14px;
    --mat-optgroup-label-text-tracking: normal;
    --mat-optgroup-label-text-weight: 400;
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-tracking: normal;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(
        --secondary-font
    );

    --mat-option-hover-state-layer-color: var(--surface-color);
    --mat-option-focus-state-layer-color: var(--surface-color);

    .mat-button-toggle .mat-pseudo-checkbox {
        display: none;
    }
}
