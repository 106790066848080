.hx-tags-holder {
    --tag-margin-bottom: 6px;
    &.bubble-holder {
        --tag-margin-bottom: 8px;
    }
    overflow-x: hidden;
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: flex-end;
    &:not(.rtl) {
        .hx-btn {
            margin-left: 0px !important;
        }
        .hx-tag,
        .hx-btn {
            &:not(.bubble) {
                margin-right: 6px;
            }
            &.bubble {
                margin-right: 8px;
            }
        }
    }
    &.rtl {
        .hx-tag,
        .hx-btn {
            &:not(.bubble) {
                margin-left: 6px;
            }
            &.bubble {
                margin-left: 8px;
            }
        }
    }
    &:not(.single-line) {
        &:not(.no-margin-bottom-fix) {
            margin-bottom: calc(-1 * var(--tag-margin-bottom));
        }
        .hx-tag,
        .hx-btn {
            margin-bottom: var(--tag-margin-bottom);
        }
        &:not(.rtl) {
            flex-flow: row wrap;
        }
        &.rtl {
            flex-flow: row-reverse wrap;
        }
    }
    &.single-line.ellipsis {
        &.rtl {
            direction: rtl;
        }
        display: inline-block;
        .hx-tag {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.hx-input-tags-holder {
    border: 1px var(--border) solid;
    &.clickable {
        cursor: pointer;
        &:hover {
            border-color: var(--border-accent);
        }
    }
    padding: 6px;
    min-height: 36px;
    border-radius: var(--hx-input-radius-px);
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: flex-end;
    align-items: center;
}

.hx-profile-pictures-holder {
    --tag-margin-bottom: 3px;
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: flex-end;
    margin-bottom: calc(-1 * var(--tag-margin-bottom));
    hx-profile-picture {
        margin-right: 5px !important;
        margin-bottom: var(--tag-margin-bottom);
    }
    &:not(.single-line) {
        flex-wrap: wrap;
        flex-flow: row wrap;
    }
}

.hx-tag {
    height: 22px;
    line-height: 20px;
    & > i {
        &:first-child:not(.icon-plus):not(.icon-x) {
            line-height: inherit;
            // margin-right: 2px;
        }
        &:first-child.icon-plus {
            line-height: 21px;
        }
        height: 22px;
        text-align: center;
    }
    text-align: center;
    // max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--secondary-font);
    background: var(--surface-color);
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: var(--hx-tag-radius-px);
    padding: 0px 5px;
    &.big {
        --btn-height: 28px;
        --border-width: 0px;
        padding: 0px 8px;
        height: 28px;
        font-size: 14px;
        line-height: calc(var(--btn-height) - var(--border-width) * 2);
    }
    &.bubble {
        font-size: 14px;
        height: 25px;
        border-radius: var(--hx-tag-bubble-radius-px);
        padding: 1px 6px;
    }
    &.rounded {
        border-radius: 99px;
        padding: 0 8px;
        &:not(.full) {
            border-style: solid;
            border-width: 1px;
            box-sizing: border-box;
        }
    }
    font-size: 13px;
    flex-direction: row;
    display: inline-block;
    position: relative;
    &.tag-close {
        padding-right: 20px;
        .icon-x {
            right: 0px;
            position: absolute;
            margin-left: 3px;
            display: inline-block;
            font-size: inherit;
            color: inherit;
            margin-right: 4px;
            line-height: 21px;
            &:hover {
                color: var(--accent);
                transform: scale(1.2);
            }
        }
    }
    &:not(.tag-close) {
        // ? USE tag-close CLASS
        .icon-x {
            color: purple !important;
        }
    }
}

.hx-tag {
    &.tag-operator {
        color: var(--secondary-font);
        background: transparent;
    }
    &.tag-transparent {
        color: var(--secondary-font);
        background: transparent;
        border: solid 1px var(--border-color);
    }

    &.tag-white {
        color: var(--secondary-font);
        background: var(--main-background);
        border: solid 1px var(--border-color);
    }

    &.tag-black {
        color: var(--primary-font);
        background: var(--surface-color);
        &.full {
            color: var(--primary-font);
            background: var(--surface-color);
        }
    }

    &.tag-grey {
        color: var(--secondary-font);
        background: var(--surface-color);
        &.full {
            color: var(--secondary-font);
            background: var(--surface-color);
        }
    }

    &.tag-blue {
        color: var(--blue-text);
        background: var(--blue-10);
        &.full {
            background: var(--blue);
        }
    }

    &.tag-lblue {
        color: var(--lblue-text);
        background: var(--lblue-10);
        &.full {
            background: var(--lblue);
        }
    }

    &.tag-green {
        color: var(--green-text);
        background: var(--green-10);
        &.full {
            background: var(--green);
        }
    }

    &.tag-yellow {
        color: var(--yellow-text);
        background: var(--yellow-10);
        &.full {
            background: var(--yellow);
        }
    }

    &.tag-orange {
        color: var(--orange-text);
        background: var(--orange-10);
        &.full {
            background: var(--orange);
        }
    }

    &.tag-red {
        color: var(--red-text);
        background: var(--red-10);
        &.full {
            background: var(--red);
        }
    }

    &.tag-purple {
        color: var(--purple-text);
        background: var(--purple-10);
        &.full {
            background: var(--purple);
        }
    }

    &.tag-lpurple {
        color: var(--lpurple-text);
        background: var(--lpurple-10);
        &.full {
            background: var(--lpurple);
        }
    }

    &.tag-pink {
        color: var(--pink-text);
        background: var(--pink-10);
        &.full {
            background: var(--pink);
        }
    }

    &.tag-lpink {
        color: var(--lpink-text);
        background: var(--lpink-10);
        &.full {
            background: var(--lpink-text);
        }
    }

    &.tag-azure {
        color: var(--azure-text);
        background: var(--azure-10);
        &.full {
            background: var(--azure);
        }
    }

    &.tag-lyellow {
        color: var(--lyellow-text);
        background: var(--lyellow-10);
        &.full {
            background: var(--lyellow);
        }
    }

    &.tag-lgreen {
        color: var(--lgreen-text);
        background: var(--lgreen-10);
        &.full {
            background: var(--lgreen);
        }
    }
}

.hx-tag {
    &.full {
        &[class*="tag-"]:not(.tag-grey):not(.tag-black):not(
                .tag-transparent
            ):not(.tag-white) {
            color: white;
        }
    }
}

.hx-sticker {
    line-height: 20px;
    font-size: 13px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 3px;
    color: var(--secondary-font);
    fill: var(--secondary-font);
    background: var(--surface-color);
}
