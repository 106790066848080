input,
textarea {
    &::placeholder {
        color: var(--secondary-font);
    }
}

.h-dropdown,
.h-textarea,
.h-input {
    transition:
        border-color 0.25s ease-in-out,
        box-shadow 0.1s ease-in-out,
        background-color 0.25s ease-in-out,
        color 0.25s ease-in-out;
    outline: 0;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--white);
    --h-input-padding-vertical: 8px;
    --h-input-padding-horizontal: 10px;
    --h-input-arrow-icon-padding-offset: 25px;

    &:not(.no-padding) {
        &:not(.mat-mdc-select) {
            padding-left: var(--h-input-padding-horizontal);
            padding-right: calc(
                var(--h-input-padding-horizontal) +
                    var(--h-input-arrow-icon-padding-offset)
            );
        }
        &.mat-mdc-select {
            .mat-mdc-select-trigger {
                padding-left: var(--h-input-padding-horizontal);
                padding-right: var(--h-input-padding-horizontal);
            }
        }
        &.iso-px {
            padding-left: var(--h-input-padding-horizontal);
            padding-right: var(--h-input-padding-horizontal);
        }
    }

    &.big:not(.no-padding) {
        &:not(.mat-mdc-select) {
            line-height: 22px;
            padding-top: var(--h-input-padding-vertical);
            padding-bottom: var(--h-input-padding-vertical);
        }
        &.mat-mdc-select {
            .mat-mdc-select-trigger {
                height: 100%;

                line-height: 21px;
                padding-top: var(--h-input-padding-vertical);
                padding-bottom: var(--h-input-padding-vertical);
            }
        }
    }
    width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    line-height: 18px;
    color: var(--primary-font);
    font-family: inherit;
    font-size: 14px;
    word-break: break-word;
    white-space: unset;
    &.small {
        height: 35px;
        min-height: 35px;
    }
    &::placeholder {
        color: var(--secondary-font);
        font-style: italic;

        // Firefox
        opacity: 1;
    }
    &:not(.disabled):hover {
        border-color: var(--blue);
        .h-dropdown-right-action {
            color: var(--primary-font);
        }
    }
    &:focus {
        border-color: var(
            --blue
        ); // box-shadow: 0 0 0 3px rgba(66, 180, 252, 0.4);
    }
}

.placeholder {
    color: var(--secondary-font);
    font-style: italic;
}

.h-dropdown,
.h-resizable,
.h-input {
    min-height: 40px;
    &.narrow {
        width: 300px;
    }
}

.h-textarea {
    resize: none;
    overflow: auto;
}

.h-resizable {
    resize: vertical;
}

.dropdown-menu
    input:not([type="checkbox"]):not(.custom-score):not(.hx-dropdown-input),
.dropdown-header
    input:not([type="checkbox"]):not(.custom-score):not(.hx-dropdown-input),
.h-dropdown-menu
    input:not([type="checkbox"]):not(.custom-score):not(.hx-dropdown-input) {
    @extend .h-input;
    height: 35px;
    min-height: 35px;
}

.discovery-create-feedback-textinput .cke_editable {
    resize: none;
    overflow: auto;
}

.h-dropdown {
    appearance: none;
    position: relative;
    cursor: pointer;
    padding-right: 3.42857em;
    text-align: left;
    line-height: 36px;
}

.h-dropdown-right-action {
    position: absolute;
    right: 0;
    width: 36px;
    top: 0;
    bottom: 0;
}

.input-label {
    margin-bottom: 8px;
    font-weight: 500;
}

.form-field {
    margin: 5px 0 20px;

    .label-horizontal {
        color: var(--secondary-font);
        margin-right: 32px;
        padding: 0;
        min-width: 60px;
    }

    .label {
        color: var(--secondary-font);
        margin-bottom: 8px;
        font-weight: 500;
        padding: 0;
        width: 100%;
    }
    .loader {
        float: right;
        margin-right: 12px;
        margin-top: -32px;
    }
}

.input-error {
    font-weight: 400;
    color: #e6252a;
    margin-bottom: 4px;
    font-style: italic;
}

.settings-input-small {
    word-break: break-all;
    word-wrap: break-word;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 150ms ease;
    background-color: var(--surface-color);
    text-align: left;
    position: relative;
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    margin-bottom: 8px;
    position: relative;
    min-height: 40px;
    cursor: pointer;
    font-size: 18px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-line;
    text-align: justify;
    font-size: 14px;
    width: 200px;
    min-width: 0px;
    &:hover {
        background: var(--border-color);
    }
}

.h-dropdown.disabled,
.h-textarea.disabled,
.h-input.disabled,
input[disabled="true"],
.disabled-input {
    color: var(--secondary-font);
    cursor: default;
    border: none;
    background-color: var(--surface-color);
    border-color: var(--border-color);
    -khtml-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    outline: none;
    pointer-events: none;
}

.readonly-input {
    color: var(--primary-font);
    cursor: pointer;
    border: none;
    background-color: var(--surface-color);
    border-color: var(--border-color);
    outline: none;
}
