.hx-field-container {
    padding: 8px;
    min-width: 220px;
    min-height: 57px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    cursor: pointer;
    background: var(--main-background);
    border: 1px solid var(--border-color);

    .hx-field-title {
        flex: 1 1;
        color: var(--secondary-font);
    }

    .field-value-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        color: var(--primary-font);

        &.multi-field-value {
            .field-value {
                margin-right: 6px;
            }
        }
    }
}

.hx-field-container:hover {
    background: var(--surface-color);
}

.hx-field-container + .hx-field-container {
    border-top: none;
}

.list-block-container {
    margin-bottom: 8px;
    width: 100px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
}
