.modal-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // z-index: 1000;
    z-index: -1;
}

.hx-dialog-actions,
.cdk-dialog-actions {
    min-height: 32px !important;
}

.closed {
    .modal {
        top: -50%;
    }
    .modal-overlay {
        display: none;
    }
}

.modal {
    overflow-y: auto;
    padding: 16px 8px 8px 16px;
    box-shadow:
        0 12px 15px 0 rgba(0, 0, 0, 0.22),
        0 17px 20px 0 rgba(0, 0, 0, 0.12);
    background: var(--dialog-background);
    max-height: calc(100% - 10em);
    width: 600px;
    // z-index: 1100;
    transition: all 0.5s ease;
    border-radius: 5px;
    font-size: 14px;
}

.modal-title {
    display: inline-flex;
    width: 100%;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 20px;
}

.modal {
    // ? scrollbar code
    --scrollbar-margin: 3px;
    --scrollbar-width: 13px;
    &::-webkit-scrollbar-track {
        z-index: 7;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px var(--roadmap--scrollbar-track);
        border: solid var(--scrollbar-margin) transparent;
    }

    &::-webkit-scrollbar {
        z-index: 7;
        width: var(--scrollbar-width);
        height: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-thumb {
        z-index: 7;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px var(--roadmap--scrollbar-thumb);
        border: solid var(--scrollbar-margin) transparent;
    }
}

.dialog-pa0 {
    .mat-dialog-container,
    .mat-mdc-dialog-container,
    .cdk-dialog-container {
        padding: 0px;
    }
}
