@font-face {
    font-family: "hx-icons9";
    src:
        url("../../assets/fonts/hx-icons9.ttf?kio1wt") format("truetype"),
        url("../../assets/fonts/hx-icons9.woff?kio1wt") format("woff"),
        url("../../assets/fonts/hx-icons9.svg?kio1wt#hx-icons9") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="iconhx9-"],
[class*=" iconhx9-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "hx-icons9" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconhx9-summarize:before {
    content: "\e926";
}
.iconhx9-webhook:before {
    content: "\e925";
}
.iconhx9-suggest:before {
    content: "\e924";
}
.iconhx9-suggest-2:before {
    content: "\e922";
}
.iconhx9-suggest-3:before {
    content: "\e923";
}
.iconhx9-harvestr:before {
    content: "\e921";
    color: #f7513a;
}
.iconhx9-magic:before {
    content: "\e920";
}
.iconhx9-arrow-down:before {
    content: "\e900";
}
.iconhx9-arrow-right:before {
    content: "\e901";
}
.iconhx9-blockquote:before {
    content: "\e902";
}
.iconhx9-code-block:before {
    content: "\e903";
}
.iconhx9-code-inline:before {
    content: "\e904";
}
.iconhx9-csv-import:before {
    content: "\e905";
}
.iconhx9-dropdown:before {
    content: "\e906";
}
.iconhx9-feedback:before {
    content: "\e907";
}
.iconhx9-fill-horizontal:before {
    content: "\e908";
}
.iconhx9-fill-vertical:before {
    content: "\e909";
}
.iconhx9-fixed-horizontal:before {
    content: "\e90a";
}
.iconhx9-fixed-vertical:before {
    content: "\e90b";
}
.iconhx9-flask:before {
    content: "\e90c";
}
.iconhx9-icon-kanban:before {
    content: "\e90d";
}
.iconhx9-icon-table:before {
    content: "\e90e";
}
.iconhx9-icon-three-panels:before {
    content: "\e90f";
}
.iconhx9-join:before {
    content: "\e910";
}
.iconhx9-list-numbered:before {
    content: "\e911";
}
.iconhx9-merge-duplicates:before {
    content: "\e912";
}
.iconhx9-mover:before {
    content: "\e913";
}
.iconhx9-multiselect:before {
    content: "\e914";
}
.iconhx9-puzzle:before {
    content: "\e915";
}
.iconhx9-relation:before {
    content: "\e916";
}
.iconhx9-rightbar:before {
    content: "\e917";
}
.iconhx9-shink-horizontal:before {
    content: "\e918";
}
.iconhx9-shink-vertical:before {
    content: "\e919";
}
.iconhx9-smile-add:before {
    content: "\e91a";
}
.iconhx9-sub-discovery:before {
    content: "\e91b";
}
.iconhx9-triple-circle:before {
    content: "\e91c";
}
.iconhx9-unjoin:before {
    content: "\e91d";
}
.iconhx9-unread:before {
    content: "\e91e";
}
.iconhx9-upvote:before {
    content: "\e91f";
}
