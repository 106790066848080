[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.text-center {
    text-align: center;
    display: block;
}

.text-top {
    vertical-align: text-top;
}

.secondary-text {
    color: var(--secondary-font);
}

.paragraph {
    margin-bottom: 16px;
    line-height: 20px;
}

.part-separator {
    margin: 0px 5px;
    height: 1px;
    background-color: var(--border-color);
}

.part-separator-spaced {
    @extend .part-separator;
    margin: 24px 5px;
}

.billing-price-indicator {
    display: inline-block;
    text-align: right;
}

.billing-price-indicator > .price {
    font-weight: 700;
    font-size: 36px;
    color: var(--primary-font);
    display: inline-block;
    position: relative;
}

.billing-price-indicator > .interval {
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    min-width: 3.2ch;
    color: var(--secondary-font);
    display: inline-block;
}

.billing-price-indicator > .price:before {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-12px);
    content: "$";
    font-weight: 700;
    font-size: 14px;
    color: currentColor;
    letter-spacing: 1.56px;
}

.billing-price-indicator > .price.usd:before {
    content: "$";
}

.billing-price-indicator > .price.eur:before {
    content: "€";
}

.plan-cost.billing-price-indicator > .interval {
    max-width: 60px;
}

.inlined:before {
    content: " ";
    display: inline-block;
    width: 4px;
}

.inline-link {
    color: var(--accent);
    cursor: pointer;
}

.inline-link:hover {
    text-decoration: underline;
}

.via {
    color: var(--secondary-font);
    @extend .inlined;
    display: block;
}

.clickable-text {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.clickable-part {
    cursor: pointer;
    &:hover {
        background-color: var(--surface-color);
    }
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
    color: var(--accent);
}

.back-link {
    cursor: pointer;
    color: var(--secondary-font);
    &:hover {
        color: var(--accent);
    }
}

.inline-field {
    display: flex;
    flex-direction: row;
    height: 20px;
    align-items: center;
    .label {
        color: var(--secondary-font);
        margin-right: 0.5rem;
        width: fit-content;
        min-width: 0px;
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .value {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        user-select: text;
    }
    & + .inline-field {
        margin-top: 0.5rem;
    }
}

.actionbar-label {
    font-weight: 500;
    color: var(--secondary-accent-font);
    font-size: 14px;
    // text-transform: uppercase;
}

.message-content *,
.message-content {
    &:not(.nocontentformat *):not(.nocontentformat):not(li > label) {
        line-height: 22px;
        :is(h1, h2, h3, h4, h5, h6):first-child {
            margin-block-start: 0px;
        }
        h1 {
            line-height: 36px;
        }
        h2 {
            line-height: 30px;
        }
        &:not(code):not(hr):not(ul > li):not(li > div) {
            padding-top: 2px !important;
            padding-bottom: 4px !important;
            word-break: break-word;
        }
        // max-width: 100%; -> causes: https://app.clubhouse.io/harvestrio/story/5043/when-chunking-bold-content-in-note-chunk-is-displayed-with-line-breaks-after-each-letter
    }
}

.formatted-text-container,
.message-content,
.comment-content,
.description-viewer {
    width: fit-content;
    word-break: break-word;
    white-space: normal;
    cursor: auto;
    user-select: text;
    img {
        max-width: 100%;
    }
    a {
        text-decoration: underline;
        cursor: pointer;
        color: var(--accent);
    }

    br:not([class]) {
        display: block;
        content: " ";
    }

    a[href^='https://www.notion.so'],
    a[href^='https://www.figma.com'],
    a[href^='https://figma.com'],
    a[href^='https://linear.app'],
    a[href^='https://gitlab.com'],
    a[href^='https://github.com'],
    a[href^='https://trello.com'],
    a[href*='atlassian.net'] {
        text-decoration: none !important;
        color: var(--primary-font) !important;
        background: var(--surface-color);
        border-radius: 3px;
        padding: 3px 5px;

        &:before {
            margin-right: 4px;
        }
        &[href^='https://www.figma.com'],
        &[href^='https://figma.com']
        {
            &:before {
                font-family: "hx-icons" !important;
                content: "\b91bf";
            }
        }
        &[href^='https://www.notion.so']
        {
            &:before {
                font-family: "hx-icons10" !important;
                content: "\e928";
            }
        }
        &[href^='https://linear.app']
        {
            &:before {
                font-family: "hx-icons10" !important;
                content: "\e927";
                color: #6671dd;
            }
        }
        &[href^='https://gitlab.com']
        {
            &:before {
                font-family: "harvestr-icons" !important;
                content: "\e9cf";
                color: #e24329;
            }
        }
        &[href^='https://github.com']
        {
            &:before {
                font-family: "harvestr-icons" !important;
                content: "\e9ce";
                color: #1a1919;
            }
        }
        &[href^='https://trello.com']
        {
            &:before {
                font-family: "harvestr-icons" !important;
                content: "\e9d7";
                color: #0079bf;
            }
        }
        &[href*="atlassian.net"] {
            &:before {
                font-family: "hx-icons" !important;
                content: "\f91df";
                color: #2684ff;
            }
        }

        &:hover {
            text-decoration: underline !important;
        }
    }

    ul,
    ol {
        padding-left: 22px;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    ul[data-type="taskList"] {
        list-style: none;
        padding: 0;
        li {
            display: flex;
            & > label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
                user-select: none;
                line-height: 28px;
            }
            & > div {
                flex: 1 1 auto;
            }
        }
    }
    code {
        font-size: 85%;

        background-color: var(--rdialog--footer-background);
        font-family: SFMono-Regular, Menlo, Consolas, "PT Mono",
            "Liberation Mono", Courier, monospace;

        .text-link {
            color: var(--roadmap--card-primary-font);
        }
    }
    :not(pre) {
        code {
            border-radius: 3px;
            padding-left: 4px;
            padding-right: 4px;
            display: inline-block;
        }
    }
    pre {
        code {
            line-height: 22px;
            display: block;
            flex-grow: 1;
            flex-shrink: 1;
            text-align: left;
            tab-size: 2;
            padding-left: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-right: 16px;
            min-height: 1em;
            white-space: pre;
            border-radius: 6px;

            &[class^="language-"] {
                background: #1e1e1e;
                color: #dcdcdc;
            }
        }
    }
    p {
        &:empty::after {
            content: "\a";
            white-space: pre;
        }
    }
    blockquote {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-left: 16px;
        margin-bottom: 4px;
        margin-top: 4px;
        word-wrap: break-word;
        text-align: left;
        position: relative;
        &::before {
            background: var(--border-accent);
            border-radius: 8px;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            width: 4px;
        }
    }

    hr {
        outline: none;
        border: 0;
        border-top: solid 1px var(--border-color);
        padding: 0px;
    }
}

.clickable-content-images {
    img {
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
}

.description-viewer {
    width: 100%;
    position: relative;
    // margin-left: -6px;
    &.readonly {
        padding: 4px 6px;
        user-select: text;
        border-radius: 3px;
        border: solid 1px transparent;
    }
}

.summary-content {
    ul {
        li:not(:first-child) {
            margin-top: 4px !important;
        }
    }
}
.comment-content {
    white-space: normal;
    line-height: 22px;
    strong {
        font-weight: 500;
    }
    ol,
    ul {
        margin: 0px;
        padding-left: 28px;
    }
    li {
        padding: 0px !important;
    }
}

.alert {
    border-radius: 0.25rem;
    // padding: 0.75rem 1.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    &.alert-warning {
        background: var(--yellow-20);
        border-color: var(--yellow);
        color: var(--yellow-70);
    }
    &.alert-danger {
        background: var(--red-20);
        border-color: var(--red);
        color: var(--red-70);
    }
    &.alert-success {
        background: var(--green-20);
        border-color: var(--green);
        color: var(--green-70);
    }
}

.mat-tree-node {
    color: var(--primary-font);
}

.wysiwyg-editor-textarea-mih-140 {
    .ProseMirror {
        min-height: 140px;
    }
}

.editor-container {
    background-color: var(--main-background);
    border: solid 1px var(--border-color);
    --editor-radius: var(--hx-input-radius-px);
    border-radius: var(--editor-radius);
    // overflow: hidden; // will deactivate sticky header
    outline: none;

    .editor-toolbar {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: var(--editor-radius) var(--editor-radius) 0px 0px;

        position: sticky;
        z-index: 99;
        background-color: var(--white);

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        &.topbar {
            top: 0;
            border-bottom: solid 1px transparent;
        }
        &.bottombar {
            bottom: 0;
        }
    }

    &:focus-within {
        border-color: var(--border-accent);
        .editor-toolbar {
            &.topbar {
                border-bottom: solid 1px var(--border-color);
            }
        }
        .btn-action {
            color: var(--primary-font);
            &.selected {
                color: var(--accent);
            }
        }
    }
    &.nocontentformat {
        line-height: 18px;
        padding: 8px 8px;
        color: var(--secondary-font);
    }
    .ProseMirror {
        padding: 8px 12px;
        outline: none;
        img {
            max-width: 100%;
        }
    }

    // * placeholder styling
    .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: var(--secondary-font);
        pointer-events: none;
        height: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    // * multiline placeholder styling
    .ProseMirror.multiline-placeholder p.is-empty::before {
        color: var(--secondary-font);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .btn-action {
        color: var(--secondary-font);
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        display: inline-block;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        outline: none;
        width: fit-content;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        height: 24px;
        padding: 4px 5px;
        line-height: 16px;
        &.btn-action-icon {
            width: 28px;
        }
        text-align: center;
        &:hover {
            background: var(--surface-color);
        }
        &.menu-toggle:after {
            padding-left: 0px;
        }
    }
    .action-separator {
        width: 1px;
        height: 18px;
        margin: 0px;
        background-color: var(--border-color);
    }
}

.mention-inline-tag {
    border: 1px solid var(--border-color);
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
    padding-top: 2px !important;
    padding-bottom: 3px !important;
    font-weight: 500;
    font-size: 14px;
}

.editor-floating {
    background-color: var(--main-background);
    box-shadow: 0 4px 26px rgba(0, 0, 0, 0.25);
}

.wysiwyg-on-top-of-tabs {
    z-index: 1000;
}

.ai-text {
    background: var(--summarize-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
