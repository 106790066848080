.ps__rail-x,
.ps__rail-y {
    z-index: 999;
}

:root {
    --scrollbarWidth: 6px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #0000 #0000;

    &:hover {
        scrollbar-color: var(--scrollbarThumbColor) #0000;
    }

    ::-webkit-scrollbar,
    ::-webkit-scrollbar-corner {
        background: var(--scrollbarColor);
    }

    ::-webkit-scrollbar {
        height: 6px;
        width: var(--scrollbarWidth);
    }
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-corner {
        background: var(--scrollbarColor);
    }
    ::-webkit-scrollbar-thumb {
        background: var(--scrollbarThumbColor);
        border-radius: 2px;
        &:hover {
            background: var(--scrollbarThumbHoverColor) !important;
            border-radius: 2px;
        }
    }
}
