._borderFocusWithin {
    height: 28px;
    border: 1px solid transparent;
    border-radius: 2px;
    position: relative;
    box-sizing: border-box;
    margin: 1px 0;
    width: 100%;
    &:hover {
        border: 1px solid var(--border-color);
    }
    &:not(:disabled):focus-within {
        height: 30px;
        margin: 0;
        border: 1px solid var(--accent);
        outline: 1px solid var(--accent);
        outline-offset: -2px;
    }
}

.colorSelectContainer {
    @extend ._borderFocusWithin;
}

.imageUploadContainer {
    @extend ._borderFocusWithin;
}

.sliderContainer {
    @extend ._borderFocusWithin;
    user-select: none;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    height: 28px;
    margin: 1px 0;
    box-sizing: border-box;
    .leftIconLabel {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 32px;
        flex: 0 0 32px;
        line-height: 32px;
        color: var(--secondary-font);
        grid-column-end: span 4;
        // pointer-events: none;
        text-align: center;
    }
    input.rawInput {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        &[type="number"] {
            -moz-appearance: textfield;
        }
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:focus {
            outline: none;
            border-color: transparent;
        }
        color: var(--primary-font);
    }
    .rawInput {
        color: var(--primary-font);
        border-radius: 2px;
        cursor: default;
        box-sizing: border-box;
        background-clip: padding-box;
        background-color: transparent;
        width: 100%;
        // padding: 0 0 0 7px;
        padding: 0px;
        height: 28px;
        border: 1px solid transparent;
        background-clip: padding-box;
        min-width: 0;
    }
}

.colorSelectContainer,
.imageUploadContainer,
.sliderContainer {
    .preview-square {
        border-radius: 1px;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
        height: 16px;
        width: 16px;
        flex: 0 0 16px;
        margin: 6px 0 6px 7px;
        border: 1px solid var(--border-color);
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .icon-asText {
        font-size: 12px;
    }
}
