@use "styles-hx";
@use "@angular/material" as mat;

@include mat.checkbox-density(-1);

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

@include mat.core();

$harvestr-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: mat.$yellow-palette
        ),
        typography: (
            plain-family: "Inter",
            brand-family: "Inter",
            bold-weight: 500
        )
    )
);

html {
    @include mat.all-component-themes($harvestr-theme);
}

// fix blinking tooltips on mouse leave
.mat-mdc-tooltip-hide {
    display: none !important;
}
