table.h-table {
    margin: 0 12px 7px 0;
    border: 1px solid var(--border-color);
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 90vw;

    table td,
    table th {
        border: 1px solid #eee;
        padding: 12px 12px 12px 24px;
    }
}

.h-table .no-margin {
    margin: 0 0 0 0;
}

.h-table .no-border {
    margin: 0 0 0 0;
    border: none;
}

.h-table.table--fixed {
    /*Flexbox logic */
    display: flex;
    flex: 1;
    flex-flow: column;
    border-spacing: 0;
    border-collapse: collapse;
    border: none;
    margin: 0;
    width: 100%;

    /* Styling */
    thead {
        border-bottom: solid 1px var(--border-color);

        th {
            border: none;
            font-weight: 500;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    tbody {
        tr {
            height: 56px;
            border-bottom: solid 1px var(--border-color);
        }

        tr:hover {
            background-color: var(--surface-color);
        }

        td {
            border: none;
        }
    }

    th,
    td {
        padding: 0px 0px 0 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .virtualscroll-viewport {
        height: 100%;
    }

    tbody {
        tr:last-of-type {
            margin-bottom: 64px;
        }
    }

    thead {
        display: block;

        tr {
            margin-right: 16px; // scrollbar width
            display: flex;

            th {
                flex: 1;
            }

            th:nth-child(2) {
                flex: 0 0 20px;
            }
        }
    }

    tbody {
        height: 100%;
        overflow-y: auto;
        width: 100%;
        display: block;

        tr {
            display: flex; // flex-wrap:nowrap;

            td {
                flex: 1; // display:flex;
                // justify-content:flex-start;
            }

            td:nth-child(2) {
                flex: 0 0 26px;
            }
        }
    }
}

.hx-contributors-table {
    min-width: 800px;
    max-width: 90vw;

    display: flex;
    flex-direction: column;

    margin: 0 12px 7px 0;
    border-collapse: collapse;
    border-spacing: 0;

    margin: 0;
    width: 100%;

    .virtualscroll-viewport {
        height: 100%;
    }

    --row-height: 40px;
    &.double-line {
        --row-height: 48px;
    }

    .cx-header,
    .cx-row {
        padding-left: 8px;
        padding-right: 16px;
        border-bottom: 1px solid var(--border-color);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cx-header {
        height: 40px;
        font-weight: 500;
        color: var(--secondary-font);
    }
    .cx-row {
        height: var(--row-height);
        &:hover {
            background-color: var(--surface-color);
        }
    }

    .cell-checkbox {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cell-name {
        flex: 1;
        min-width: 250px;
    }
    .cell-messages {
        width: 110px;
    }
    .cell-discoveries {
        width: 120px;
    }
    .cell-persons {
        width: 80px;
    }
    .cell-groups {
        width: 260px;
        -webkit-mask-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 0) 0,
            rgb(0, 0, 0) 20px
        );
        mask-image: linear-gradient(to left, #0000 0, #000 20px);
    }
}
