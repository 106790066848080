.integration-card {
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: solid 1px var(--border-color);
    cursor: pointer;
    background-color: var(--white);
    color: var(--primary-font);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
    .title {
        height: 22px;
    }
    .integration-card-icon,
    .svg-icon {
        margin-right: 16px;
        margin-top: 3px;
        padding: 8px;
        border-radius: 6px;
        font-size: 32px;
        height: 48px;
        width: 48px;
    }
    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);
    }
}

.integration-card-icon,
.brand,
.svg-icon {
    background: #f9f9f9;
    // screeb
    &.screeb {
        background: #f9f9f9;
    }
    // hfc
    &.iconhx8-suggest-3 {
        background: linear-gradient(136deg, #f64c34 -1%, #f3823f 88.73%);
        color: white;
    }
    // automations
    &.iconhx8-webhook {
        background: linear-gradient(136deg, #d94fff -1%, #343cfa 160.73%);
        color: white;
    }
    // webhook
    &.icon-radio {
        background: linear-gradient(136deg, #14b649 -1%, #46d5cf 98.73%);
        color: white;
    }
    // api
    &.icon-api {
        background: linear-gradient(136deg, #343cfa -1%, #5dc8d0 88.73%);
        color: white;
    }
    // gitlab
    &.icon-brand6 {
        background: #31353d;
    }
    // github
    &.icon-brand5 {
        &::before {
            color: white;
        }
        background: rgb(51, 51, 51);
    }
    // zendesk
    &.icon-brand17 {
        &::before {
            color: white;
        }
        background: rgb(2, 54, 61);
    }
    // jira
    &.iconhx-jira {
        &::before {
            color: white;
        }
        background: rgb(0, 78, 212);
    }
    // trello
    &.icon-brand14 {
        &::before {
            color: white;
        }
        background: rgb(0, 121, 191);
    }
    // freshdesk
    &.iconhx-freshdesk {
        &::before {
            color: white;
        }
        background: #25c16f;
    }
    // intercom
    &.icon-brand10 {
        &::before {
            color: white;
        }
        background: #286efa;
    }
    // slack
    &.iconhx-slack {
        &::before {
            color: white;
        }
        background: rgb(74, 21, 75);
    }
    // mail
    &.icon-mail {
        &::before {
            color: white;
        }
        background: #0773c4;
    }
    // salesforce
    &.iconhx-salesforce {
        &::before {
            color: white;
        }
        background: #00a1e0;
    }
    &.iconhx-hubspot {
        &::before {
            color: white;
        }
        background: #ff7a59;
    }
    // zapier
    &.icon-brand16 {
        &::before {
            color: white;
        }
        background: #ff4a00;
    }
    // chrome
    &.chrome {
        // &::before {
        //     color: white;
        // }
        background: #f8f8fa;
    }
    // csv
    &.icon-brand12 {
        &::before {
            color: white;
        }
        background: #217346;
    }
    // figma
    &.figma {
        // &::before {
        //     color: white;
        // }
        background: #2b2e34;
    }
    // linear
    &.linear {
        // &::before {
        //     color: white;
        // }
        padding: 0;
    }
}
