.sidebar-menu-line {
    &.active,
    &.selected {
        background: var(--border-color);
        color: var(--accent);
        .text {
            font-weight: 500;
        }
    }
    &:not(.readonly) {
        cursor: pointer;
        &:hover {
            background: var(--border-color);
        }
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 6px;
    padding: 2px 0px;
    font-weight: 400;
    height: 30px;
    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
        vertical-align: middle;
        white-space: nowrap;
        line-height: 30px;
        flex: 0 1 auto;
    }
}

.sidebar-create-line {
    @extend .sidebar-menu-line;
    font-weight: 400;
    display: flex;
    flex: 1;
    color: var(--secondary-font);
}

.sidebar-icon {
    margin: 0 12px 0 2px;
    font-size: 14px;
}
