@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
    box-sizing: border-box;
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-family);
    text-rendering: optimizeLegibility;
}

html,
body {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

*,
:after,
:before {
    box-sizing: inherit;
}

body {
    user-select: none;
    color: var(--primary-font);
    font-size: 14px;
}

textarea:focus,
input:focus {
    outline: 1px auto var(--input-outline-color);
}

button[disabled="true"],
html input[disabled="true"] {
    cursor: default;
}

.holder {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

pre {
    margin: 16px 0;
    white-space: initial;
}

p {
    margin: 0em;
}

ul {
    display: block;
    list-style-type: disc;
}

ul,
ol {
    margin: 0em;
    -webkit-padding-start: 32px;
}

button {
    cursor: pointer;
}

button,
input {
    font-size: 100%;
    line-height: 16px;
}

ng-component {
    flex: 1;
}

h4 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
}

app-modal {
    position: fixed;
    z-index: 99999;
    height: 100%;
    top: 0;
}

/* for search highlighting pipe */

:host ::ng-deep .search-highlight {
    background-color: var(--search-highlight);
    font-size: inherit;
    font: inherit;
}

.hx-dialog-actions,
.cdk-dialog-actions {
    margin-bottom: auto !important;
}

.ps__rail-x,
.ps__rail-y {
    z-index: 9999;
}

button::-moz-focus-inner {
    border: 0;
}
