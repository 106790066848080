@font-face {
    font-family: "hx-icons10";
    src:
        url("../../assets/fonts/hx-icons10.ttf?su4fdb") format("truetype"),
        url("../../assets/fonts/hx-icons10.woff?su4fdb") format("woff"),
        url("../../assets/fonts/hx-icons10.svg?su4fdb#hx-icons10") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="iconhx10-"],
[class*=" iconhx10-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "hx-icons10" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconhx10-linear:before {
    content: "\e927";
    color: #6671dd;
}
.iconhx10-notion:before {
    content: "\e928";
    color: #000000;
}
.iconhx10-summarize:before {
    content: "\e926";
}
.iconhx10-webhook:before {
    content: "\e925";
}
.iconhx10-suggest:before {
    content: "\e924";
}
.iconhx10-suggest-2:before {
    content: "\e922";
}
.iconhx10-suggest-3:before {
    content: "\e923";
}
.iconhx10-harvestr:before {
    content: "\e921";
    color: #f7513a;
}
.iconhx10-magic:before {
    content: "\e920";
}
.iconhx10-arrow-down:before {
    content: "\e900";
}
.iconhx10-arrow-right:before {
    content: "\e901";
}
.iconhx10-blockquote:before {
    content: "\e902";
}
.iconhx10-code-block:before {
    content: "\e903";
}
.iconhx10-code-inline:before {
    content: "\e904";
}
.iconhx10-csv-import:before {
    content: "\e905";
}
.iconhx10-dropdown:before {
    content: "\e906";
}
.iconhx10-feedback:before {
    content: "\e907";
}
.iconhx10-fill-horizontal:before {
    content: "\e908";
}
.iconhx10-fill-vertical:before {
    content: "\e909";
}
.iconhx10-fixed-horizontal:before {
    content: "\e90a";
}
.iconhx10-fixed-vertical:before {
    content: "\e90b";
}
.iconhx10-flask:before {
    content: "\e90c";
}
.iconhx10-icon-kanban:before {
    content: "\e90d";
}
.iconhx10-icon-table:before {
    content: "\e90e";
}
.iconhx10-icon-three-panels:before {
    content: "\e90f";
}
.iconhx10-join:before {
    content: "\e910";
}
.iconhx10-list-numbered:before {
    content: "\e911";
}
.iconhx10-merge-duplicates:before {
    content: "\e912";
}
.iconhx10-mover:before {
    content: "\e913";
}
.iconhx10-multiselect:before {
    content: "\e914";
}
.iconhx10-puzzle:before {
    content: "\e915";
}
.iconhx10-relation:before {
    content: "\e916";
}
.iconhx10-rightbar:before {
    content: "\e917";
}
.iconhx10-shink-horizontal:before {
    content: "\e918";
}
.iconhx10-shink-vertical:before {
    content: "\e919";
}
.iconhx10-smile-add:before {
    content: "\e91a";
}
.iconhx10-sub-discovery:before {
    content: "\e91b";
}
.iconhx10-triple-circle:before {
    content: "\e91c";
}
.iconhx10-unjoin:before {
    content: "\e91d";
}
.iconhx10-unread:before {
    content: "\e91e";
}
.iconhx10-upvote:before {
    content: "\e91f";
}
