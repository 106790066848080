app-spinner.loading-veil {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: rgba(255, 255, 255, 0.4);
    height: 100%;
    width: 100%;
    z-index: 199;
    backdrop-filter: blur(4px) grayscale(1);
}
