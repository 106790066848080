.sticky_sentinel {
    position: absolute;
    left: 0;
    right: 0; /* needs dimensions */
    // visibility: hidden;
}
.sticky_sentinel--top {
    /* Adjust the height and top values based on your on your sticky top position.
    e.g. make the height bigger and adjust the top so observeHeaders()'s
    IntersectionObserver fires as soon as the bottom of the sentinel crosses the
    top of the intersection container. */
    height: 1px;
    top: 0px;
    visibility: hidden;
}
.sticky_sentinel--bottom {
    bottom: 0;
    /* Height should match the top of the header when it's at the bottom of the
    intersection container. */
    height: 1px;
    visibility: hidden;
}

// ? roadmap
.roadmap-card-frame {
    // & + .roadmap-card-frame {
    margin-bottom: 12px;
    // }
}

.sidebar-menu-line--drag-placeholder {
    min-height: 30px;
    background: var(--border-color);
    border-radius: 3px;
    display: block;
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.field-option-line--drag-placeholder {
    min-height: 30px;
    background: var(--border-color);
    border-radius: 3px;
    display: block;
    position: relative;
    margin: 0.5rem 0;
}

.cdk-drag-preview > .hx-sidebar-menu-line {
    cursor: grabbing;
    box-shadow:
        0 12px 24px -6px rgba(9, 45, 66, 0.25),
        0 0 0 1px rgba(9, 45, 66, 0.08);
    transform: rotate(4deg) !important;
}

.roadmap-card--drag-placeholder {
    min-height: 60px;
    background: var(--border-color);
    border-radius: 3px;
    display: block;
    position: relative;
    min-width: 280px;
    max-width: 284px;
    margin-bottom: 10px; // transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview > .roadmap-card {
    cursor: grabbing;
    box-shadow:
        0 12px 24px -6px rgba(9, 45, 66, 0.25),
        0 0 0 1px rgba(9, 45, 66, 0.08);
    transform: rotate(4deg) !important;
}

.roadmap-column {
    // ? adds padding at the end of scroll list
    &:after {
        --scroll-padding-right: 32px;
        content: "";
        display: block;
        position: absolute;
        right: calc(-1 * var(--scroll-padding-right));
        width: var(--scroll-padding-right);
        height: 100%;
    }
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    background: var(--roadmap--column-background);
    border-radius: var(--roadmap--column-border-radius);
    max-height: 100%;
    transition: width ease-out 150ms;
    position: relative;
    border: solid 1px var(--roadmap--column-border-color);
    &:not(.collapsed) {
        width: 320px;
        min-width: 320px;
    }
    &.collapsed {
        background-color: var(--roadmap--column-collapsed-background);
        border: solid 1px var(--roadmap--column-collapsed-border-color);
        width: 56px;
        min-width: 56px;
        height: 300px;
        margin-left: 16px;

        .col-header {
            writing-mode: vertical-lr;
            padding: 0;
            padding-left: 10px;
            padding-top: 40px;
            align-items: flex-start;
            text-align: right;

            .title {
                margin: 0;
                transform: rotate(-180deg);
            }

            .description {
                transform: rotate(-180deg);
                height: 248px;
            }
        }
    }
    .col-header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: var(--roadmap--column-header-align-items);
        align-content: stretch;
        padding: var(--roadmap--column-header-padding);

        .title {
            color: var(--primary-font);
            font-size: 18px;
            font-weight: var(--roadmap--column-header-font-weight);
            margin-bottom: 8px;
        }

        .description {
            font-size: 13px;
            line-height: normal;
            color: var(--secondary-font);
            margin-bottom: 8px;
        }
    }

    .collapse-actions {
        position: absolute;
        top: 4px;
        right: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        width: 100%;
        .collapse-cta {
            font-size: 20px;
        }
    }

    .col-body {
        margin-right: 4px;
        padding: 0px 4px 0px 8px;
        overflow-y: auto;
        margin-bottom: 0;
        position: relative;
        scrollbar-width: thin;

        .single-scroll-column-container {
            min-height: 40px;
        }
        // ? scrollbar code
        --scrollbar-margin: 0px;
        --scrollbar-width: 7px;
        &::-webkit-scrollbar-track {
            z-index: 7;
            border-radius: 20px;
            box-shadow: inset 0 0 10px 10px var(--roadmap--scrollbar-track);
            border: solid var(--scrollbar-margin) transparent;
        }

        &::-webkit-scrollbar {
            z-index: 7;
            width: var(--scrollbar-width);
            height: var(--scrollbar-width);
        }

        &::-webkit-scrollbar-thumb {
            z-index: 7;
            border-radius: 20px;
            box-shadow: inset 0 0 10px 10px var(--roadmap--scrollbar-thumb);
            border: solid var(--scrollbar-margin) transparent;
        }
    }
}

.roadmap-column-subgroup {
    --header-height: 40px;
    --sticky-pos: 0px;
    display: block;
    background: var(--roadmap--subgroup-background);
    border-radius: var(--roadmap--subgroup-border-radius);
    border: 1px solid var(--roadmap--subgroup-border-color);
    box-shadow: var(--roadmap--subgroup-box-shadow);
    // & + .roadmap-column-subgroup {
    margin-bottom: 12px;
    // }
    position: relative;
    padding-bottom: 0px;
    // box-shadow: inset 0px 0px 0px 1px var(--border-color);
    transition: box-shadow 0.4s;
    .header {
        z-index: 1;
        transition: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        height: var(--header-height);
        padding: 0 8px;
        &.sticky-scrolled:not(.collapsed) {
            position: sticky;
            border-radius: 0 !important;
            //border-radius: var(--roadmap--subgroup-border-radius)
            //    var(--roadmap--subgroup-border-radius) 0px 0px;
            margin-left: -1px;
            margin-top: -1px;
            margin-right: -1px;
            margin-bottom: 12px;
            border-top: var(--roadmap--subgroup-border-color) solid 1px;
            border-left: var(--roadmap--subgroup-border-color) solid 1px;
            border-right: var(--roadmap--subgroup-border-color) solid 1px;
            //border-bottom: solid 1px
            //    var(--roadmap-subgroup-sticky-header-border-color);
            background: var(--roadmap-subgroup-sticky-header-background);
        }

        .header-title {
            color: var(--primary-font);
            flex: 1;
            font-weight: 500;
            font-size: 14px;
        }
        .actions {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            .collapse-cta {
                font-size: 20px;
            }
        }
    }
    .body {
        padding: 0px 8px;
        transition: all 0.2s ease-out;
        .scroll-subgroup-content {
            margin-top: 4px;
            min-height: 40px;
        }
        &.collapsed {
            padding-top: 0;
            color: var(--secondary-font);
        }
    }

    // ? sticky handle
    .sticky {
        border-radius: 3px 3px 0px 0px;
        // border-top: 1px var(--border-color) solid;
        // border-left: 1px var(--border-color) solid;
        // border-right: 1px var(--border-color) solid;
        top: var(--sticky-pos); /* Sentinels adjust based on this position. */
        height: var(--header-height);
        display: flex;
        align-items: center;
    }
}

.hx-preview-mode-striped-border {
    height: 4px;
    width: 100%;
    background: repeating-linear-gradient(
        45deg,
        var(--preview-mode-a),
        var(--preview-mode-a) 10px,
        var(--preview-mode-b) 10px,
        var(--preview-mode-b) 20px
    );
}

.hx-preview-background {
    background: var(--preview-mode-c);
}

.roadmap-container {
    --scrollbar-height: 14px;
    --scrollbar-margin: 3px;
    &::-webkit-scrollbar-track {
        z-index: 7;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px var(--roadmap--scrollbar-track);
        border: solid var(--scrollbar-margin) transparent;
    }

    &::-webkit-scrollbar {
        z-index: 7;
        width: var(--scrollbar-height);
        height: var(--scrollbar-height);
    }

    &::-webkit-scrollbar-thumb {
        z-index: 7;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px var(--roadmap--scrollbar-thumb);
        border: solid var(--scrollbar-margin) transparent;
    }
}

.sidebar-container {
    .key-value-line {
        display: flex;
        --key-width: 130px;
        --key-min-height: 40px;
        --key-line-height: 24px;
        .key {
            padding: calc((var(--key-min-height) - var(--key-line-height)) / 2)
                0;
            display: flex;
            flex: 1 0;
            color: var(--secondary-accent-font);
            width: var(--key-width);
            max-width: var(--key-width);
            min-width: var(--key-width);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: var(--key-line-height);
            .label {
                line-height: inherit;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                // flex: 1;
            }
        }
        .value {
            min-width: 0;
            flex: 2 0;
            color: var(--primary-font);
            // text-align: end;
            // float: right;
            // clear: right;
            // flex-direction: row-reverse;
            min-height: var(--key-min-height);
            display: flex;
            align-items: center;
            .hx-tags-holder,
            .hx-profile-pictures-holder {
                min-height: 40px;
                cursor: pointer;
                border-radius: 3px;
                flex-direction: inherit;
                &:hover {
                    background-color: var(--main-background);
                    box-shadow: inset 0px 0px 0px 1px var(--border-color);
                }
                --tag-line-height: 25px;
                padding-top: calc(
                    (var(--key-min-height) - var(--tag-line-height)) / 2
                );
                padding-bottom: calc(
                    (var(--key-min-height) - var(--tag-line-height)) / 2
                );
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
}

.roadmap-rightbar {
    --bar-width: 230px;
    width: var(--bar-width);
    min-width: var(--bar-width);
    max-width: var(--bar-width);
    right: 0;
    border-left: solid 1px var(--border-color);
    padding: 8px 8px;
    height: 100%;
    background-color: var(--main-background);
}
