.inline-channel-icon {
    --inline-channel-icon-width: 24px;
    --inline-channel-icon-height: 24px;
    &:not(.clickable) {
        --inline-channel-icon-width: 16px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--inline-channel-icon-height);
    min-height: var(--inline-channel-icon-height);
    width: var(--inline-channel-icon-width);
    min-width: var(--inline-channel-icon-width);
    margin: 0px 6px;
    border-radius: 50%;
    font-size: 13px;
    border: solid 1px transparent;
    &.clickable {
        border: solid 1px var(--border-color);
        cursor: pointer;
        &:hover {
            background-color: var(--surface-color);
        }
    }
}

.feedback-card-content {
    display: block;
    width: fit-content;
    background-color: var(--surface-color);
    width: 100%;
    .message-content {
        width: 100%;
    }
    padding: 0px 16px 0px 8px;
    border-left: solid 3px var(--border-color);

    & + .feedback-card-content {
        margin-top: 8px;
    }

    &.full-link {
        width: fit-content;
        word-break: break-word;
        white-space: normal;
        user-select: text;
        line-height: 22px;
        // background: var(--white);
        // border: solid 1px var(--border-color);
        padding-top: 8px;
        padding-bottom: 8px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
