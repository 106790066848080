.hx-input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    &[type="number"] {
        -moz-appearance: textfield;
    }
    height: 36px;
    min-height: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0px 10px;

    &.small {
        height: 32px;
        min-height: 32px;
    }
}

.hx-input,
.hx-input-tags-holder {
    & + .hx-input,
    & + .hx-btn,
    & + .hx-input-tags-holder {
        margin-left: 6px;
    }
}

.hx-textarea {
    &:not(.no-padding) {
        padding: 10px;
        min-height: 56px;
    }
}

.hx-textarea,
.hx-input {
    background-color: transparent;
    font-size: 14px;
    color: var(--primary-font);
    border-radius: var(--hx-input-radius-px);

    border: 1px solid var(--border-color);
    &::placeholder {
        color: var(--secondary-font);
    }
    // TODO @valshg COLORS
    &.disabled,
    &[disabled="true"],
    &:disabled {
        color: #b0b0b0;
        cursor: default;
        border: none;
        background-color: #f6f6f6;
        border-color: #eeeeee;
        user-select: none;
        outline: none;
        pointer-events: none;
    }
}

.hx-inline-value-list + .hx-inline-value-list {
    &::before {
        content: ", ";
    }
}
