.hx-separator {
    margin: 8px 0;
    border-top: 1px solid var(--border-color);
    &.accent {
        border-top: 1px solid var(--border-accent);
    }
}

.hx-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: var(--primary-font);
}

.hx-subtitle {
    color: var(--primary-font);
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.hx-description {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--secondary-font);
}

.hx-color-primary,
.hx-color-black {
    color: var(--black);
    &.no-ow {
        background: var(--root-black);
    }
}
.hx-color-grey {
    color: var(--grey);
    &.no-ow {
        color: var(--root-grey);
    }
}
.hx-color-red {
    color: var(--red);
    &.no-ow {
        color: var(--root-red);
    }
}
.hx-color-orange {
    color: var(--orange);
    &.no-ow {
        color: var(--root-orange);
    }
}
.hx-color-yellow {
    color: var(--yellow);
    &.no-ow {
        color: var(--root-yellow);
    }
}
.hx-color-lyellow {
    color: var(--lyellow);
    &.no-ow {
        color: var(--root-lyellow);
    }
}
.hx-color-lgreen {
    color: var(--lgreen);
    &.no-ow {
        color: var(--root-lgreen);
    }
}
.hx-color-green {
    color: var(--green);
    &.no-ow {
        color: var(--root-green);
    }
}
.hx-color-lblue {
    color: var(--lblue);
    &.no-ow {
        color: var(--root-lblue);
    }
}
.hx-color-blue {
    color: var(--blue);
    &.no-ow {
        color: var(--root-blue);
    }
}
.hx-color-azure {
    color: var(--azure);
    &.no-ow {
        color: var(--root-azure);
    }
}
.hx-color-lpurple {
    color: var(--lpurple);
    &.no-ow {
        color: var(--root-lpurple);
    }
}
.hx-color-purple {
    color: var(--purple);
    &.no-ow {
        color: var(--root-purple);
    }
}
.hx-color-lpink {
    color: var(--lpink);
    &.no-ow {
        color: var(--root-lpink);
    }
}
.hx-color-pink {
    color: var(--pink);
    &.no-ow {
        color: var(--root-pink);
    }
}

.hx-bg-color-black {
    background: var(--black);
    &.no-ow {
        background: var(--root-black);
    }
}
.hx-bg-color-grey {
    background: var(--grey);
    &.no-ow {
        background: var(--root-grey);
    }
}
.hx-bg-color-red {
    background: var(--red);
    &.no-ow {
        background: var(--root-red);
    }
}
.hx-bg-color-orange {
    background: var(--orange);
    &.no-ow {
        background: var(--root-orange);
    }
}
.hx-bg-color-yellow {
    background: var(--yellow);
    &.no-ow {
        background: var(--root-yellow);
    }
}
.hx-bg-color-lyellow {
    background: var(--lyellow);
    &.no-ow {
        background: var(--root-lyellow);
    }
}
.hx-bg-color-lgreen {
    background: var(--lgreen);
    &.no-ow {
        background: var(--root-lgreen);
    }
}
.hx-bg-color-green {
    background: var(--green);
    &.no-ow {
        background: var(--root-green);
    }
}
.hx-bg-color-lblue {
    background: var(--lblue);
    &.no-ow {
        background: var(--root-lblue);
    }
}
.hx-bg-color-blue {
    background: var(--blue);
    &.no-ow {
        background: var(--root-blue);
    }
}
.hx-bg-color-azure {
    background: var(--azure);
    &.no-ow {
        background: var(--root-azure);
    }
}
.hx-bg-color-lpurple {
    background: var(--lpurple);
    &.no-ow {
        background: var(--root-lpurple);
    }
}
.hx-bg-color-purple {
    background: var(--purple);
    &.no-ow {
        background: var(--root-purple);
    }
}
.hx-bg-color-lpink {
    background: var(--lpink);
    &.no-ow {
        background: var(--root-lpink);
    }
}
.hx-bg-color-pink {
    background: var(--pink);
    &.no-ow {
        background: var(--root-pink);
    }
}

.hx-inner-border {
    box-shadow: inset 0px 0px 0px 1px var(--border-color);
}

.hx-inner-border-bottom {
    box-shadow: inset 0px -1px 0px 0px var(--border-color);
}

.hx-inner-border-top {
    box-shadow: inset 0px 1px 0px 0px var(--border-color);
}

.hx-inner-border-left {
    box-shadow: inset 1px 0px 0px 0px var(--border-color);
}
.hx-hidden {
    display: none !important;
}
.hx-readonly {
    cursor: initial !important;
}
