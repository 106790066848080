.hx-mat-dialog .mat-dialog-container,
.hx-mat-dialog .mat-mdc-dialog-container,
.hx-mat-dialog .cdk-dialog-container {
    padding: 0;
    border-radius: var(--hx-dialog-radius-px);
    display: block;
    box-sizing: border-box;
    box-shadow: 0 4px 26px rgba(0, 0, 0, 0.25);
    overflow: auto;
    outline: 0;
    width: 100%;
    min-height: inherit;
    max-height: inherit;
    color: var(--primary-font);
}

.hx-mat-dialog .hx-dialog-content,
.hx-mat-dialog .cdk-dialog-content {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 0;
    overflow: auto;
    max-height: 65vh;
    // max-height: 88vh;
}

.hx-mat-dialog .hx-dialog-actions,
.hx-mat-dialog .cdk-dialog-actions {
    height: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    background: var(--dialog-background);
    padding: 0 8px;
    &:not(.noborder) {
        border-top: 1px solid var(--border-color);
    }
    margin-bottom: 0 !important;
}

.hx-mat-dialog .hx-title,
.hx-mat-dialog .cdk-dialog-title {
    margin: 0;
    padding: 0 16px;
    min-height: 32px;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background: var(--dialog-background);
    margin-bottom: 12px;
}

.hx-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hx-dialog-close {
    padding: 0 16px;
}
