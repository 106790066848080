.message-content.highlight-snippets {
    snippet,
    .snippet {
        background-color: var(--snippet-color);
        display: inline;
        cursor: pointer;
        &.hover {
            background-color: var(--snippet-hover);
        }
    }
}
