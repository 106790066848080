.radio-button {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition:
        transform 0.2s ease,
        -webkit-transform 0.2s ease;
    box-shadow:
        0 0 0 2px var(--white),
        0 0 0 4px var(--secondary-font);
    cursor: pointer;
    margin: 5px;
}

.radio-button.selected {
    background-color: var(--blue);
}

.mdc-checkbox__background {
    margin: 2px !important;
    border-width: 1px !important;
    border-radius: 3px;
    width: 14px !important;
    height: 14px !important;
}

.mat-mdc-checkbox-touch-target {
    width: var(--mdc-checkbox-state-layer-size) !important;
    height: var(--mdc-checkbox-state-layer-size) !important;
}

.mdc-checkbox__checkmark,
.mdc-checkbox__mixedmark {
    color: var(--white) !important;
}

// unchecked
.mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
        [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: var(--primary-font) !important;
}

// checked or intermediate
.mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
.mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
.mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    background-color: var(--accent) !important;
    border-color: var(--accent) !important;
}

//fix checkbox ripple animation
.mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none;
}
