%tag {
    white-space: nowrap;
    color: var(--secondary-font);
    fill: var(--secondary-font);
    background-color: var(--surface-color);
    max-width: 200px;
    width: fit-content;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    border-radius: 3px;
    .icon-x {
        display: inline-block;
        font-size: inherit;
        color: inherit;
        &:hover {
            color: var(--accent);
            transform: scale(1.2);
        }
    }
}

.tag-card {
    @extend %tag;
    &.clickable:hover {
        color: var(--primary-font);
        fill: var(--primary-font);
        background-color: var(--surface-color);
    }
    padding: 0px 4px;
    font-size: 13px;
    height: 20px;
    line-height: 18px;
    &.big {
        padding: 0px 6px;
        font-size: 13px;
        height: 24px;
        line-height: 22px;
    }
    &.square {
        border-radius: 3px;
    }
}

.tag-card.clickable {
    cursor: pointer;
}

.add-tag-button-big {
    &:hover {
        background-color: var(--surface-color);
        color: var(--primary-font);
    }
    font-size: 13px;
    height: 22px;
    background-color: transparent;
    color: var(--secondary-font);
    padding: 0px 8px;
    cursor: pointer;
    margin-bottom: 6px;
    @extend .tag-card;
    border-radius: 3px;
    background-color: var(--white);
    color: var(--primary-font);
    border: solid 1px var(--border-color);
    padding-right: 6px;
    i {
        color: var(--accent);
    }
    font-size: 14px;
    height: 26px;
}

.tags-holder {
    overflow-x: hidden;
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: flex-end;
    margin-bottom: -4px;
    .tag-card,
    .h-button.smaller {
        &:not(.big) {
            margin-right: 5px !important;
            margin-bottom: 3px;
        }
        &.big {
            margin-right: 5px !important;
            margin-bottom: 5px;
        }
    }
    &:not(.single-line) {
        flex-wrap: wrap;
        flex-flow: row wrap;
    }
}

.more-tag {
    min-width: fit-content;
}

.trello-card-tag {
    @extend %tag;
    height: 15px;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    padding: 0 4px;
}

.white-tag {
    background: white;
    border: solid 1px var(--border-color);
    color: var(--secondary-font);
}

.black-tag {
    color: var(--primary-font);
    fill: var(--primary-font);
    background: var(--surface-color);
}

.grey-tag {
    color: var(--secondary-font);
    fill: var(--secondary-font);
    background: var(--surface-color);
}

.blue-tag {
    color: var(--blue-text);
    fill: var(--blue-text);
    background: var(--blue-10);
    &.clickable:hover {
        background-color: var(--blue-20);
        color: var(--blue-60);
    }
}

.red-tag {
    color: var(--red-text);
    fill: var(--red-text);
    background: var(--red-10);
    &.clickable:hover {
        background-color: var(--red-20);
        color: var(--red-60);
    }
}

.orange-tag {
    color: var(--orange-text);
    fill: var(--orange-text);
    background: var(--orange-10);
    &.clickable:hover {
        background-color: var(--orange-20);
        color: var(--orange-60);
    }
}

.lblue-tag {
    color: var(--blue-text);
    fill: var(--blue-text);
    background: var(--blue-10);
    &.clickable:hover {
        background-color: var(--lblue-20);
        color: var(--lblue-60);
    }
}

.bullet-holder {
    font-size: 0;
    display: flex;
    align-items: center;
    // margin: 0px 0px 14px 0px;
    flex-wrap: wrap;
    flex-flow: row wrap;
}

.bullet {
    width: 16px;
    height: 16px;
    border-radius: 9px;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition:
        transform 0.2s ease,
        -webkit-transform 0.2s ease;
    color: var(--secondary-font);
    background: currentColor;
    &.small {
        height: 8px;
        width: 8px;
        border-radius: 99px;
        display: inline-block;
    }
}

.selectable-bullet {
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }
}

.bullet-selected {
    box-shadow:
        0 0 0 2px var(--main-background),
        0 0 0 3px currentColor;
}

.black-text,
.black-bullet {
    color: var(--primary-font);
}

.grey-text,
.grey-bullet {
    color: var(--secondary-font);
}

.red-text,
.red-bullet {
    color: var(--red-text);
}

.orange-text,
.orange-bullet {
    color: var(--orange-text);
}

.yellow-text,
.yellow-bullet {
    color: var(--yellow-text);
}

.green-text,
.green-bullet {
    color: var(--green-text);
}

.lgreen-text,
.lgreen-bullet {
    color: var(--lgreen);
}

.blue-text,
.blue-bullet {
    color: var(--blue);
}

.link-text,
.link-bullet {
    color: var(--accent);
}

.lblue-text,
.lblue-bullet {
    color: var(--lblue-text);
}

.azure-text,
.azure-bullet {
    color: var(--azure-text);
}
.lyellow-text,
.lyellow-bullet {
    color: var(--lyellow);
}
.pink-text,
.pink-bullet {
    color: var(--pink);
}
.lpink-text,
.lpink-bullet {
    color: var(--lpink);
}
.purple-text,
.purple-bullet {
    color: var(--purple);
}
.lpurple-text,
.lpurple-bullet {
    color: var(--lpurple);
}

.black-indicator {
    background-color: var(--surface-color);
}

.grey-indicator {
    background-color: var(--secondary-font);
}

.red-indicator {
    background-color: var(--red);
}

.orange-indicator {
    background-color: var(--orange);
}

.yellow-indicator {
    background-color: var(--yellow);
}

.green-indicator {
    background-color: var(--green);
}

.lgreen-indicator {
    background-color: var(--lgreen);
}

.blue-indicator {
    background-color: var(--blue);
}

.lblue-indicator {
    background-color: var(--lblue);
}

.azure-indicator {
    background-color: var(--azure);
}

.lyellow-indicator {
    background-color: var(--lyellow);
}

.pink-indicator {
    background-color: var(--pink);
}

.lpink-indicator {
    background-color: var(--lpink);
}

.purple-indicator {
    background-color: var(--purple);
}

.lpurple-indicator {
    background-color: var(--lpurple);
}

.chips {
    display: block;
    position: absolute;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: var(--white);
    background: var(--red);
    z-index: 10;
    top: -1px;
    right: -1px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
}

.black-state,
.black-fill {
    color: var(--primary-font);
    fill: var(--primary-font);
    background: var(--surface-color);
}

.grey-state,
.grey-fill {
    color: var(--secondary-font);
    fill: var(--secondary-font);
    background-color: var(--surface-color);
}

.blue-state,
.blue-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--blue);
}

.green-state,
.green-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--green);
}

.yellow-state,
.yellow-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--yellow);
}

.red-state,
.red-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--red);
}

.orange-state,
.orange-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--orange);
}

.lblue-state,
.lblue-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--lblue);
}

.lgreen-state,
.lgreen-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--lgreen);
}

.azure-state,
.azure-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--azure);
}
.lyellow-state,
.lyellow-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--lyellow);
}
.pink-state,
.pink-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--pink);
}
.lpink-state,
.lpink-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--lpink);
}
.purple-state,
.purple-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--purple);
}
.lpurple-state,
.lpurple-fill {
    color: var(--white);
    fill: var(--white);
    background-color: var(--lpurple);
}

.blue-state,
.green-state,
.yellow-state,
.red-state,
.orange-state,
.lblue-state,
.lgreen-state,
.azure-state,
.lyellow-state,
.pink-state,
.lpink-state,
.purple-state,
.lpurple-state {
    &:hover {
        background-color: var(--secondary-font);
    }
}

// AZURE
// LYELLOW
// PINK
// LPINK
// PURPLE
// LPURPLE
