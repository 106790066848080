.parsed-link {
    background: var(--surface-color);
    padding: 2px 8px;
    border-radius: 3px;
    color: var(--primary-font) !important;
    text-decoration: none !important;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin: 0 4px;
    font-size: 1em !important;
    line-height: 22px !important;

    &:hover {
        span {
            text-decoration: underline !important;
        }
    }
}
