:root {
    // gradient colors
    --hxb-primary-font: #f7513a;
    --hxb-primary-gradient: linear-gradient(
        160deg,
        #ff6a49 9.83%,
        #f7453a 86.46%
    );

    --hxb-page-header-background: black;
}
