.slideInUp {
    animation-name: slideInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    0% {
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        transform: translateY(0);
    }
}

form > p {
    line-height: 1.6em;
    margin-bottom: 10px;
}

label.required,
form .required {
    &:not(.branded) {
        &::after {
            content: "*";
            padding-left: 3px;
            font-weight: 400;
        }
    }
}

form .optional::after {
    content: "(optional)";
    color: var(--secondary-font);
    font-size: 11px;
    padding-right: 3px;
    padding-left: 3px;
    font-weight: 400;
}

.form-element {
    margin-bottom: 1rem;
}

.field {
    min-height: 42px;
    padding: 0 0 30px;
}

.form-error {
    color: var(--red);
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    background-color: #fff2f2;
    border: solid 1px var(--red);
    padding: 8px;
    border-radius: 4px;
    overflow-wrap: anywhere;
}

.form-input-error {
    color: var(--red);
    font-size: 14px;
    margin-top: 0.5rem;
    overflow-wrap: anywhere;
}

.hx-input.invalid-input {
    border-color: var(--red);
}

.form-warning {
    color: var(--orange);
    width: 100%;
    font-size: 14px;
    line-height: 22px;

    background-color: #fff8f2;
    border: solid 1px var(--yellow-60);
    padding: 8px;
    border-radius: 4px;
    overflow-wrap: anywhere;
}

.forms-submit-footer {
    position: fixed;
    left: 64px;
    bottom: 16px;
    height: 44px;
    margin-bottom: 24px;
    background-color: #ffffff;
    animation-name: slideInUp;
    animation-duration: 0.3s;
    z-index: 520;
    border-radius: 6px;
    margin: auto;
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: lch(100 0 0);
    box-shadow:
        lch(0 0 0 / 0.188) 0px 3px 8px,
        lch(0 0 0 / 0.188) 0px 2px 5px,
        lch(0 0 0 / 0.188) 0px 1px 1px;
}

.form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 0 0;
    align-items: center;
    right: 0 !important;
    margin-top: 8px;
}
