.weight-bar {
    width: 100px;
    height: 2px;
    background-color: var(--border-color);
    background-color: rgba(188, 231, 249, 0.54);
    .progress {
        background-color: var(--blue);
        height: 2px; // background-color: var(--blue);
    }
}
