// ? How to use:
/***
   <div class="h-control h-switch">
       <input type="checkbox" class="h-control-input" id="customSwitch1" />
       <label class="h-control-label" for="customSwitch1">
           Enabled switch element
       </label>
   </div>
   <div class="h-control h-switch">
       <input
           type="checkbox"
           class="h-control-input"
           disabled
           id="customSwitch2"
       />
       <label class="h-control-label" for="customSwitch2">
           Disabled switch element
       </label>
   </div>
**/

.h-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    print-color-adjust: exact;
    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box;
        padding: 0;
    }
    label {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
    .h-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        line-height: 24px;
        &::before {
            transition:
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            background-color: #fff;
            border: #adb5bd solid 1px;
        }
        &::after {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
            background: no-repeat 50%/50% 50%;
        }
    }

    &.pointer {
        .h-control-label {
            &::before {
                cursor: pointer;
            }
            &::after {
                cursor: pointer;
            }
        }
    }

    .h-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
        &:checked ~ .h-control-label::before {
            color: #fff;
            border-color: var(--accent);
            background-color: var(--accent);
        }
        // ? disabled
        &:disabled ~ .h-control-label::before,
        &[disabled="true"] ~ .h-control-label::before {
            background-color: #e9ecef;
        }
        &:disabled ~ .h-control-label,
        &[disabled="true"] ~ .h-control-label {
            color: #6c757d;
        }
    }

    &.h-switch {
        padding-left: 2.25rem;
        .h-control-label {
            &::before {
                left: -2.25rem;
                width: 1.75rem;
                pointer-events: all;
                border-radius: 0.5rem;
            }
            &::after {
                top: calc(0.25rem + 2px);
                left: calc(-2.25rem + 2px);
                width: calc(1rem - 4px);
                height: calc(1rem - 4px);
                background-color: #adb5bd;
                border-radius: 0.5rem;
                transition:
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out,
                    -webkit-transform 0.15s ease-in-out;
                transition:
                    transform 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                transition:
                    transform 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out,
                    -webkit-transform 0.15s ease-in-out;
            }
        }
        .h-control-input {
            &:checked ~ .h-control-label::after {
                background-color: #fff;
                transform: translateX(0.75rem);
            }
        }
    }
}

.h-double-choice {
    background: var(--surface-color);
    display: flex;
    padding: 4px;
    border-radius: 5px;
    width: 170px;
    font-size: 0.83em;
    &__item {
        text-align: center;
        background: transparent;
        color: var(--secondary-font);
        flex: 1;
        padding: 4px;
        cursor: pointer;
        font-weight: 400;
        &.active {
            border-radius: 3px;
            background: var(--border-accent);
            color: var(--primary-font);
        }
    }
}
