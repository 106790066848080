.hx-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    color: var(--primary-font);
    // background: var(--main-background);
    --space-between-columns: 8px;
    &.no-padding-first-column {
        tr td:first-child,
        tr th:first-child {
            padding-left: 0px;
        }
    }
    td,
    th {
        padding-right: var(--space-between-columns);
        padding-left: var(--space-between-columns);
        line-height: normal;
        position: relative;
    }
    // td {
    //     mask-image: linear-gradient(
    //         to left,
    //         rgba(0, 0, 0, 0) 0,
    //         rgba(0, 0, 0, 1) 20px
    //     );
    // }
    th {
        font-weight: normal;
        color: var(--secondary-font);
        text-align: left;

        &.main-header {
            width: 70%;
        }
    }

    tr {
        border-bottom: 1px solid var(--border-color);
        height: 41px;
    }

    &.big {
        thead {
            tr {
                height: 48px;
            }
        }
        tr {
            height: 64px;
            border-right: none;
            border-left: none;
        }
    }

    &.fixed {
        table-layout: fixed;
    }

    &.no-separators {
        th {
            border-bottom: 1px solid var(--border-color);
        }
        tr {
            border-bottom: none;
        }
    }

    .table-cell-fill-space {
        max-width: 1px;
        width: 100%;
    }
}
