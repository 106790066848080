.integration-holder {
    border: solid 1px var(--border-color);
    border-radius: 3px;
    padding: 8px 12px 16px 12px;
    margin-bottom: 16px;
}

.integration-holder-title {
    margin-bottom: 8px;
    color: var(--secondary-font);
}

.link-issue-box {
    background-color: var(--white);
    border: solid 1px var(--border-color);
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 7%);
}
