@font-face {
    font-family: "hx-icons8";
    src:
        url("../../assets/fonts/hx-icons8.ttf?9j5c9l") format("truetype"),
        url("../../assets/fonts/hx-icons8.woff?9j5c9l") format("woff"),
        url("../../assets/fonts/hx-icons8.svg?9j5c9l#hx-icons8") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="iconhx8-"],
[class*=" iconhx8-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "hx-icons8" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconhx8-webhook:before {
    content: "\e925";
}
.iconhx8-suggest:before {
    content: "\e924";
}
.iconhx8-suggest-2:before {
    content: "\e922";
}
.iconhx8-suggest-3:before {
    content: "\e923";
}
.iconhx8-harvestr:before {
    content: "\e921";
    color: #f7513a;
}
.iconhx8-magic:before {
    content: "\e920";
}
.iconhx8-arrow-down:before {
    content: "\e900";
}
.iconhx8-arrow-right:before {
    content: "\e901";
}
.iconhx8-blockquote:before {
    content: "\e902";
}
.iconhx8-code-block:before {
    content: "\e903";
}
.iconhx8-code-inline:before {
    content: "\e904";
}
.iconhx8-csv-import:before {
    content: "\e905";
}
.iconhx8-dropdown:before {
    content: "\e906";
}
.iconhx8-feedback:before {
    content: "\e907";
}
.iconhx8-fill-horizontal:before {
    content: "\e908";
}
.iconhx8-fill-vertical:before {
    content: "\e909";
}
.iconhx8-fixed-horizontal:before {
    content: "\e90a";
}
.iconhx8-fixed-vertical:before {
    content: "\e90b";
}
.iconhx8-flask:before {
    content: "\e90c";
}
.iconhx8-icon-kanban:before {
    content: "\e90d";
}
.iconhx8-icon-table:before {
    content: "\e90e";
}
.iconhx8-icon-three-panels:before {
    content: "\e90f";
}
.iconhx8-join:before {
    content: "\e910";
}
.iconhx8-list-numbered:before {
    content: "\e911";
}
.iconhx8-merge-duplicates:before {
    content: "\e912";
}
.iconhx8-mover:before {
    content: "\e913";
}
.iconhx8-multiselect:before {
    content: "\e914";
}
.iconhx8-puzzle:before {
    content: "\e915";
}
.iconhx8-relation:before {
    content: "\e916";
}
.iconhx8-rightbar:before {
    content: "\e917";
}
.iconhx8-shink-horizontal:before {
    content: "\e918";
}
.iconhx8-shink-vertical:before {
    content: "\e919";
}
.iconhx8-smile-add:before {
    content: "\e91a";
}
.iconhx8-sub-discovery:before {
    content: "\e91b";
}
.iconhx8-triple-circle:before {
    content: "\e91c";
}
.iconhx8-unjoin:before {
    content: "\e91d";
}
.iconhx8-unread:before {
    content: "\e91e";
}
.iconhx8-upvote:before {
    content: "\e91f";
}
