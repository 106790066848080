.color-picker {
    background: var(--menu-background) !important;
    border: solid 1px var(--modale-border) !important;
    // border-color: transparent !important;
    border-radius: 3px !important;
    box-shadow:
        0px 2px 14px rgba(0, 0, 0, 0.15),
        0px 0px 0px 0.5px rgba(0, 0, 0, 0.2);
    .hue,
    .alpha {
        border-radius: 6px;
        height: 12px !important;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0.6px inset;
    }
    .cursor {
        position: absolute;
        box-sizing: border-box;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0.6px;
        width: 12px !important;
        height: 12px !important;
        border-radius: 6px;
        border-style: solid;
        border-width: 2px;
        border-color: white !important;
    }
    .arrow {
        display: none !important;
    }

    .hex-text,
    .cmyk-text,
    .hsla-text,
    .rgba-text,
    .value-text,
    .rgba-text {
        .box {
            div {
                color: var(--secondary-font) !important;
            }
            input {
                border-color: var(--border-color) !important;
                border-radius: 1px !important;
                background: transparent !important;
            }
        }
    }
    .left {
        padding: 16px 4px !important;
    }
    .eyedropper-icon {
        mix-blend-mode: unset !important;
        height: 18px !important;
        fill: var(--primary-font) !important;
    }
    .selected-color,
    .selected-color-background {
        --box-size: 32px;
        width: var(--box-size) !important;
        height: var(--box-size) !important;
    }
    .selected-color-background {
        visibility: hidden;
    }
    .selected-color {
        left: 4px !important;
        background-color: inherit !important;
        border-radius: 3px !important;
        border: none !important;
        &:hover {
            background-color: var(--surface-color) !important;
        }
    }
    input {
        color: var(--primary-font) !important;
    }
}
