.hx-card {
    display: flex;
    flex-direction: column;
    color: var(--primary-font);
    border-radius: var(--hx-card-radius-px);
    background: var(--main-background);
    min-width: 290px;
    min-height: 180px;
    border: 1px var(--border-color) solid;
    padding: 12px 16px 0;
    margin-right: 8px;
    margin-bottom: 8px;

    box-shadow: none;
    transition: box-shadow 0.4s;

    &:hover {
        background-color: var(--roadmap--card--hover-background);
        border-color: var(--roadmap--card--hover-border-color);
        box-shadow: var(--roadmap--card--hover-box-shadow);
        transition: box-shadow 0.4s;
    }

    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
        }

        .actions {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }
    }

    .content {
        padding-top: 12px;

        flex-grow: 1;
    }

    .footer {
        min-height: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
}

.devtoolcard-container {
    background: var(--main-background);
    border: solid 1px var(--border-color);
    border-radius: 5px;
    padding: 6px 8px;
    position: relative;
    margin-bottom: 8px;

    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 0px;
    grid-template-columns: 22px 1fr;
    grid-template-rows: 22px auto;
    grid-template-areas: "avatar firstl" "blank secondl";
    &.noIcon {
        grid-template-columns: 1fr;
        grid-template-areas: "firstl" "secondl";
    }

    .devtoolcard-avatar {
        grid-area: avatar;
        &.round-card-avatar {
            --avatar-size: 20px;
            width: var(--avatar-size);
            min-width: var(--avatar-size);
            height: var(--avatar-size);
            border-radius: 50%;
        }
    }
    .devtoolcard-firstline {
        grid-area: firstl;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        // grid-area: firstline;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // .devtoolcard-avatar {
        //     --avatar-size: 20px;
        //     width: var(--avatar-size);
        //     min-width: var(--avatar-size);
        //     height: var(--avatar-size);
        //     border-radius: 50%;
        // }
        // .more-actions {
        // }
    }
    .devtoolcard-inline-props-list {
        grid-area: secondl;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &.bullet-spaced {
            span:not(:first-child)::before {
                content: "·";
                margin-left: 4px;
                margin-right: 4px;
            }
        }
    }

    .devtoolcard-expanded-content {
        margin-top: 4px;
        display: grid;
        grid-column-gap: 8px;
        grid-row-gap: 0px;
        grid-template-columns: 22px 80px 1fr;
        grid-template-rows: auto auto auto auto;
        grid-template-areas: "left firstl firstr" "left secondl secondr" "left thirdl thirdr" "left fourthl fourthr" "left fifthl fifthr" "left sixthl sixthr";

        .devtoolcard-expanded-first-row-left {
            grid-area: firstl;
        }

        .devtoolcard-expanded-first-row-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-area: firstr;
        }

        .devtoolcard-expanded-second-row-left {
            grid-area: secondl;
        }

        .devtoolcard-expanded-second-row-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-area: secondr;
        }

        .devtoolcard-expanded-third-row-left {
            grid-area: thirdl;
        }

        .devtoolcard-expanded-third-row-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-area: thirdr;
        }

        .devtoolcard-expanded-fourth-row-left {
            grid-area: fourthl;
        }

        .devtoolcard-expanded-fourth-row-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-area: fourthr;
        }

        .devtoolcard-expanded-fifth-row-left {
            grid-area: fifthl;
        }

        .devtoolcard-expanded-fifth-row-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-area: fifthr;
        }

        .devtoolcard-expanded-sixth-row-left {
            grid-area: sixthl;
        }

        .devtoolcard-expanded-sixth-row-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-area: sixthr;
        }
    }
}
