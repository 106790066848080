.backlog-list-container {
    .add-footer {
        visibility: hidden;
    }
    &:hover {
        .add-footer {
            visibility: visible;
        }
    }
}
