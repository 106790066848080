.hx-form-field {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: stretch;
    margin-bottom: 16px;
    width: 100%;
    label {
        color: var(--secondary-font);
        margin-bottom: 6px;
        //font-weight: 500;
    }

    label.primary {
        color: var(--primary-font);
    }

    .hx-input,
    .hx-textarea {
        width: 100%;
    }
    &.padding {
        padding: 0 18px;
    }
    .hx-field-description {
        font-weight: normal;
        font-size: 12px;
        color: var(--secondary-font);
    }
}
