.import-logo-topline {
    font-size: 34px;
    height: 34px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 34px;
    }
}
