textarea {
    resize: none;
    padding: 0;
    color: var(--primary-font);
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
        color: var(--secondary-font);
    }
}

.markdown-textarea-container {
    min-width: 96px;
    max-width: 540px;
    max-width: min(90%, 540px);
    display: inline-flex;
    align-items: flex-start;
    position: relative;
    height: 18.00019px;
    overflow: hidden;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    line-height: inherit;
    padding: 0;
    text-align: initial;
    vertical-align: initial;
    cursor: pointer;

    .markdown-textarea {
        position: relative;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .content {
            position: relative;
            word-break: break-word;
            overflow-wrap: break-word;
        }

        a {
            color: var(--accent);
            text-decoration: underline;
        }
        code {
            font-family:
                Monaco,
                Menlo,
                Consolas,
                Courier New,
                monospace !important;
            font-size: 12px;
            line-height: 1.50001;
            font-variant-ligatures: none;
            word-wrap: break-word;
            word-break: normal;
            tab-size: 4;
            padding: 0px 3px;
            border: 1px solid var(--border-color);
            border-radius: 3px;
            background: var(--surface-color);
            color: var(--red-text);
            margin: 0;
            white-space: nowrap;
        }
    }

    &.zoomable:hover {
        overflow: visible;
        z-index: 202;
        .markdown-textarea {
            min-width: 96px;
            text-overflow: clip;
            white-space: normal;
            overflow: visible;
            &:before {
                top: -8px;
                bottom: -8px;
                left: -12px;
                right: -12px;
                box-shadow:
                    0 0 0 1px var(--accent),
                    0 0 8px rgb(0 0 0 / 25%);
                content: "";
                position: absolute;
                background: var(--main-background);
                border-radius: 3px;
            }
            .content {
                min-width: 96px;
                text-overflow: clip;
                white-space: normal;
                overflow: visible;
            }
        }
    }
}
