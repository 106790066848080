// overlay
// .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
//     background: #000;
//     opacity: 0.85 !important;
// }
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #000;
    opacity: 0.85 !important;
}
.non-selectable-overlay {
    pointer-events: auto;
}
