.mat-mdc-tooltip {
    color: #fff;
    border-radius: 4px;
    margin: 0px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: scale(0);
    background: rgba(97, 97, 97, 0.9);
    font-size: 10px;
}
