$HEADER_HEIGHT: 40;
$LINE_HEIGHT: 38;
$TABLE_MARGIN_BOTTOM: 128;

app-view-discoveries {
    --toggle-spacer-width: 16px;
    --toggle-spacer-ml: 4px;
}

app-discovery-table {
    --toggleSpacerFullWidth: 20px; //--toggle-spacer-width + --toggle-spacer-ml
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    overflow: hidden;
    height: 100%;
    display: block;
    .cell {
        font-size: 13px;
        // padding: 0;
        margin: 0;
        vertical-align: top;
        cursor: default;
        outline: none;
        display: flex;
        flex-wrap: wrap;
        &.sticky {
            background: var(--white);
        }
    }

    .table-header {
        display: block;
        position: sticky;
        top: 0px;
        z-index: 102;
        height: $HEADER_HEIGHT + px;
        .line {
            height: inherit;
            display: inline-flex;
            .cell {
                height: inherit;
                vertical-align: top;
                border-bottom: 1px solid var(--border-color);
                border-top: none;
                background: var(--white);
                padding-top: 2px;
                &.sticky {
                    z-index: 103;
                    position: sticky;
                    top: 0px;
                    left: 0px;
                    flex-wrap: nowrap;
                }
            }
        }
        .on-header-hover:not(.active) {
            visibility: hidden;
        }
        &:hover {
            .on-header-hover {
                visibility: visible;
            }
        }
    }

    .sticky {
        position: sticky;
        left: 0;
        z-index: 99;
    }

    .name-column {
        user-select: none;
        display: flex;
        flex-direction: row;
        .on-hover {
            display: none;
        }
        &:hover {
            .on-hover {
                display: inline;
            }
        }
    }

    .dataRow {
        display: flex;
        animation: fadeIn ease 400ms;
        height: $LINE_HEIGHT + px;
        min-height: $LINE_HEIGHT + px;
        max-height: $LINE_HEIGHT + px;
        .empty-placeholder,
        .row-checkbox:not(.visible) {
            visibility: hidden;
        }
        &.hover,
        &:hover {
            .cell {
                background: var(--generic-hover);
            }
            .empty-placeholder,
            .row-checkbox {
                visibility: visible;
            }
        }
    }

    .dataRow,
    .loadMoreRow {
        z-index: 100;
        position: relative;
        .cell:not(.header) {
            border-top: none;
            &:not(.cell-description) {
                border-bottom: 1px solid var(--border-color);
            }
            &.cell-description {
                border-bottom: 1px solid transparent;
                --stickyDescriptionCellBottomBorderLeft: 0px;
                &::before {
                    content: "";
                    position: absolute;
                    height: 1px;
                    right: 0;
                    left: calc(
                        var(--toggleSpacerFullWidth) +
                            var(--stickyDescriptionCellBottomBorderLeft)
                    );
                    top: -1px;
                    background: var(--border-color);
                    pointer-events: none;
                }
            }
            border-right: none;
            overflow: visible;
            height: inherit;
        }
    }

    .groupHeader {
        z-index: 101;
        display: flex;
        height: $LINE_HEIGHT + px;
        min-height: $LINE_HEIGHT + px;
        max-height: $LINE_HEIGHT + px;
        background-color: var(--white);
        &.sticky {
            position: sticky;
            top: $HEADER_HEIGHT + px;
        }

        .cell {
            background: inherit;
            height: inherit;
            &:not(.cell-description) {
                border-bottom: 1px solid var(--border-color);
            }
            &.cell-description {
                &::after {
                    content: "";
                    position: absolute;
                    height: 1px;
                    right: 0;
                    left: var(--toggleSpacerFullWidth);
                    bottom: 0px;
                    background: var(--border-color);
                    pointer-events: none;
                }
            }
        }
    }

    .ghostStickyLinesGroup {
        --ghostStickyLinesLength: 1;
        height: 1 px;
        $HALF_MARGIN_INV: calc(
            var(--ghostStickyLinesLength) * ((#{$LINE_HEIGHT}px) * -0.5)
        );
        margin-top: ($HALF_MARGIN_INV); // ? invisible line
        margin-bottom: ($HALF_MARGIN_INV); // ? invisible line
        z-index: 103;
        background: var(--main-background);
        // ? add back border-bottom on sticky header
        .dataRow {
            .cell-description::after {
                content: "";
                position: absolute;
                height: 1px;
                right: 0;
                left: var(--toggleSpacerFullWidth);
                bottom: -1px;
                background: var(--border-color);
                pointer-events: none;
            }
        }
    }

    .buttonRow {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 1px;
            right: 0;
            left: var(--toggleSpacerFullWidth);
            top: -1px;
            background: var(--border-color);
            pointer-events: none;
            z-index: 100;
        }
    }

    .loadOlderRow,
    .buttonRow,
    .loadMoreRow,
    .emptyLine {
        height: $LINE_HEIGHT + px;
        min-height: $LINE_HEIGHT + px;
        max-height: $LINE_HEIGHT + px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .cell.sticky {
            .h-toggle,
            .hx-btn,
            .h-button {
                margin-left: 22px;
            }
        }
    }

    .checkbox-column {
        width: 14px;
        margin-left: 8px !important;
    }

    .empty-table {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
    }

    .resize-handle {
        position: absolute;
        top: 0px;
        right: -8px;
        width: 16px;
        height: 100%;
        z-index: 102;
        &.main-handle {
            z-index: 300;
        }
        cursor: ew-resize;
        .drag-line {
            height: 100%;
            margin: auto;
            border-right: solid 1px var(--border-color);
            pointer-events: none;
            padding-left: 6px;
            margin-right: 8px;
            pointer-events: none; // ? otherwise drag line can be selected when resizing event which causes blinking on resize
            &.scrolled {
                box-shadow: 4px 0 0 0 rgba(0, 0, 0, 0.04);
            }
        }
        &:hover,
        &.active {
            .drag-line {
                border-right: solid 2px var(--accent);
            }
        }
    }

    .virtual-scroll-container {
        min-height: 100%;
    }

    .scroll_bottom_margin {
        height: $TABLE_MARGIN_BOTTOM + px;
        display: block;
        width: 100%;
    }
}

.overlay-kanban-add-container {
    background-color: var(--white);
    height: 100%;
    width: 100%;
    border-radius: var(--hx-input-radius-px);
    display: flex;
    justify-content: space-between;
    // border: solid 1px var(--secondary-font);
    .add-input {
        font-size: 13px;
        background-color: transparent;
        border: none;
        padding: 2px 0px 2px 8px;
        height: 100%;
        width: 100%;
        outline: none;
    }
    .add-button {
        padding: 0px;
        border: none;
        margin: 3px;
        border-radius: var(--hx-button-radius-px);
        font-size: 13px;
        width: 60px;
    }
}

.zoomed-cell {
    position: relative;
    max-width: 400px;
    width: fit-content;
    min-width: 160px;
    z-index: 2;
    background: var(--white);
    border-radius: var(--hx-menu-radius-px);
    min-height: $LINE_HEIGHT + px;
    height: fit-content;
    border: solid 2px var(--accent);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
    padding: 0.5rem;
}

.empty-placeholder {
    color: var(--secondary-font);
    margin-top: auto;
    margin-bottom: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.discovery-toggle-content-spacer {
    width: var(--toggle-spacer-width);
    min-width: var(--toggle-spacer-width);
    margin-left: var(--toggle-spacer-ml);
}

.discovery-toggle-content {
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--toggle-spacer-width);
    margin-left: var(--toggle-spacer-ml);
    font-size: 14px;
    color: var(--secondary-font);
    cursor: pointer;
    &:hover {
        color: var(--accent);
    }
}

.ghost-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.cell-content.fade-in {
    mask-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 1) 20px
    );
}

.discovery-contributor-list-line {
    user-select: text;
    padding: 8px 8px 8px 12px;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    grid-template-areas:
        "pic topline"
        "pic botline";
    grid-template-columns: 34px 1fr;
    grid-template-rows: 22px 22px;
    border-bottom: solid 1px var(--border-color);

    .checkbox {
        input {
            margin: auto;
        }
    }

    .avatar {
        grid-area: pic;
    }

    .topline {
        grid-area: topline;
        min-width: 0px;
    }

    .bottomline {
        grid-area: botline;
        font-size: 12px;
        line-height: 16px;
        min-width: 0px;
    }

    .checkbox-visibility {
        display: none;
        &.checked {
            display: block;
        }
    }

    .avatar-visibility {
        display: block;
        &.hidden {
            display: none;
        }
    }

    &:hover {
        background-color: var(--surface-color);
        & .checkbox-visibility {
            display: block;
        }
        & .avatar-visibility {
            display: none;
        }
    }
}

.discovery-rightbar-header-content {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    border-bottom: solid 1px var(--border-color);
}
