%round-picture {
    user-select: none;
    border-radius: 50%;
    background-color: #f2c94c;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    text-transform: uppercase;
    line-height: initial;
}

.round-picture-inline {
    @extend %round-picture;
    width: 20px;
    height: 20px;
    font-size: 10px;
}

.round-picture-small {
    @extend %round-picture;
    width: 26px;
    height: 26px;
    font-size: 14px;
}

.round-picture-medium {
    @extend %round-picture;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.round-picture-big {
    @extend %round-picture;
    width: 36px;
    height: 36px;
    font-size: 18px;
}

.round-picture-huge {
    @extend %round-picture;
    width: 46px;
    height: 46px;
    font-size: 20px;
}

.user-picture {
    background: var(--red-gradient);
    color: var(--white);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.admin-picture {
    background: var(--blue-gradient);
    color: var(--white);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.company-picture {
    background-color: var(--purple);
    color: var(--white);
}
