.hx-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
    color: var(--primary-font);
    background: var(--menu-background);
    border: solid 1px var(--modale-border);
    border-radius: var(--hx-menu-radius-px);
    .hx-menu-group + .hx-menu-group {
        border-top: 1px solid var(--border-color);
    }

    .cdk-virtual-scroll-content-wrapper {
        min-width: 100%;
        width: 100%;
    }

    .hx-title {
        color: var(--primary-font);
        font-weight: 500;
        font-size: 18px;
        padding: 0;
        margin: 16px 0;

        line-height: 21px;
    }

    .hx-description {
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
    }

    .hx-menu-line {
        display: flex;
        height: 40px;
        &.small {
            height: 32px;
        }
        &.big {
            height: 48px;
        }
        padding: 0 8px;
        font-size: 14px;
        line-height: 16px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
        border-radius: var(--hx-menu-radius-px);

        .hx-description {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .hx-menu-group + .hx-menu-footer {
        border-top: none;
    }

    // ? mat-theme
    &.mat-mdc-menu-panel {
        min-width: 220px !important;
        width: fit-content !important;
        max-width: fit-content !important;
        overflow: initial;
        min-height: 0px;
        .mat-mdc-menu-content {
            min-width: 220px;
            width: fit-content;
            max-width: fit-content;
            .mat-mdc-menu-item {
                color: var(--primary-font) !important;
                --horizontal-menu-line-padding: 12px;
                height: 40px !important;
                min-height: 40px !important;
                display: flex;
                background: var(--menu-background);
                flex-direction: row;
                align-items: center;
                padding-left: var(--horizontal-menu-line-padding);
                padding-right: var(--horizontal-menu-line-padding);
                cursor: pointer;
                outline: none;
                &:not(.disable):hover,
                &.selected:not(.disable) {
                    background-color: var(--surface-color);
                }
            }
        }
    }
    .mat-mdc-menu-content {
        max-height: 100%;
        max-width: none;
    }
}

.menu-line-icon {
    --icon-size-px: 14px;
    margin-right: 6px;
    height: var(--icon-size-px);
    min-height: var(--icon-size-px);
    width: var(--icon-size-px);
    min-width: var(--icon-size-px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.hx-menu.mat-mdc-menu-panel,
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    color: var(--primary-font) !important;
    background: var(--menu-background) !important;
    border: solid 1px var(--modale-border) !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    border-radius: var(--hx-menu-radius-px);
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    border-radius: var(--hx-menu-radius-px) !important;
    padding: 0 !important;
    min-width: fit-content !important;
}

.mat-mdc-option {
    font-weight: 400;
    height: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important;
    padding: 0 8px 0px 16px !important;

    .mdc-list-item__primary-text {
        white-space: nowrap !important;
    }
}

.hx-dropdown-header {
    --horizontal-menu-line-padding: 12px;
    position: relative;
    border-bottom: solid 1px var(--border-color);
    .hx-dropdown-input {
        --horizontal-menu-line-padding: 12px;
        padding-right: var(--horizontal-menu-line-padding);
        padding-left: var(--horizontal-menu-line-padding);
        color: var(--primary-font);
        background: transparent;
        outline: none;
        border: none;
        border-radius: 0px;
        width: 100%;
        height: 40px;
    }
    .hx-tags-holder {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: var(--horizontal-menu-line-padding);
        padding-right: var(--horizontal-menu-line-padding);
        .hx-dropdown-input {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 3px;
        }
    }
}

.hx-menu-footer {
    border-top: 1px solid var(--border-color);
    max-height: 48px;
    height: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0 10px;
}

.shadow--menu {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}

.hx-unsaved-menu-dark {
    background-color: #222222;
    border-color: #222222;
    color: white;
    border-radius: var(--hx-menu-radius-px);
    box-shadow: 0 0 12px rgb(34 34 34 / 47%);
    .hx-title {
        color: white;
    }
    .btn-secondary:not(:hover) {
        color: white;
        background-color: #3e3e3e;
    }
}

.unsaved-bullet {
    height: 40px;
    width: 40px;
    border-radius: 999px;
    font-size: 18px;
    cursor: pointer;
    &:hover {
        background-color: #3a3a3a;
    }
}
