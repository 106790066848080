.close-icon {
    font-size: 32px;
}

.settings-content {
    min-width: 640px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0px 16px 32px 16px;
    display: flex;
    justify-content: center;
}

.state-card {
    box-shadow: 0px 1px 4px #adadad4a;
    border-radius: 3px;
    /* background: whitesmoke; */
    width: 300px;
    height: 34px;
    margin: 0px 0px 10px 0px;
    padding: 0px 10px;
    background: var(--white);
    cursor: pointer;
}

.state-card:hover {
    background: var(--surface-color);
}

.state-card-selected {
    background: var(--blue);
    color: white;
    font-weight: 500;
}

.state-card-selected:hover {
    background: var(--blue);
    color: white;
    font-weight: 500;
    cursor: default;
}

.description-paragraph {
    color: var(--primary-font);
    margin-bottom: 0.5rem;
}

.selectable-paragraph {
    padding: 0px 10px 24px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.selectable-paragraph:hover {
    background: var(--surface-color);
}

.settings-container {
    border: solid 1px var(--border-color);
    border-radius: 3px;
    margin-bottom: 1rem;
    padding: 0.5rem 0.75rem;
    display: block;
    .container-line {
        & + .container-line {
            border-top: solid 1px var(--surface-color);
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        .label {
            font-weight: 500;
            flex: 1 1 auto;
        }
        .value {
            padding-left: 40px;
        }
    }
}

.settings-lines-container {
    border: solid 1px var(--border-color);
    border-radius: 3px;
    margin-bottom: 1rem;
    padding: 0rem 0.75rem;
    display: block;
    .container-line {
        & + .container-line {
            border-top: solid 1px var(--surface-color);
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        .label {
            font-weight: 500;
            flex: 1 1 auto;
        }
        .value {
            padding-left: 40px;
        }
    }
}
