.iconhx-hubspot:not(.nocolor):before {
    color: #ff7a59;
}
.iconhx-salesforce:not(.nocolor):before {
    color: #00a1e0;
}
.iconhx-jira:not(.nocolor):before {
    color: #2684ff;
}
.iconhx-slack:not(.nocolor):before {
    color: #4a154b;
}
.iconhx-freshdesk:not(.nocolor):before {
    color: #25c16f;
}
.iconhx-chrome:not(.nocolor):before {
    color: #555;
}
.iconhx-harvestr:not(.nocolor):before {
    color: rgb(234, 152, 34);
}

.icon-brand:not(.nocolor):before {
    color: #007ee5;
}

.icon-brand2:not(.nocolor):before {
    color: #2dbe60;
}

.icon-brand3:not(.nocolor):before {
    color: #3b5998;
}

.icon-brand4:not(.nocolor):before {
    color: #f14e32;
}

.icon-brand5:not(.nocolor):before {
    color: #1a1919;
}

.icon-brand6:not(.nocolor):before {
    color: #e24329;
}

.icon-brand7:not(.nocolor):before {
    color: #d14836;
}

.icon-brand8:not(.nocolor):before {
    color: #4285f4;
}

.icon-brand9:not(.nocolor):before {
    color: #4386fc;
}

.icon-brand10:not(.nocolor):before {
    color: #1f8ded;
}

.icon-brand11:not(.nocolor):before {
    color: #172b4d;
}

.icon-brand12:not(.nocolor):before {
    color: #217346;
}

.icon-brand13:not(.nocolor):before {
    color: #56b68b;
}

.icon-brand14:not(.nocolor):before {
    color: #0079bf;
}

.icon-brand15:not(.nocolor):before {
    color: #1da1f2;
}

.icon-brand16:not(.nocolor):before {
    color: #ff4a00;
}

.channel.iconhx8-suggest-3 {
    color: #dd4632;
}
