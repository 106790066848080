.keyboard-shortcut {
    --borderRadius: 3px;
    --smallFontSize: 11px;
    --tagHeight: 16px;
    opacity: 0.5;
    margin-left: 10px;
    position: relative;
    font-size: var(--smallFontSize);
    color: var(--primary-font);
    height: var(--tagHeight);
    line-height: var(--tagHeight);
    margin-right: 4px;
    &::after {
        content: "";
        position: absolute;
        left: -4px;
        right: -4px;
        top: -1px;
        bottom: -1px;
        border: 1px solid var(--secondary-font);
        opacity: 0.6;
        border-radius: var(--borderRadius);
    }
}

.accent-shortcut {
    color: var(--white);
    border-color: var(--white);
}
