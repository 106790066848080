.hx-btn {
    box-sizing: border-box;
    border-radius: var(--hx-button-radius-px);
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    align-content: center;
    outline: none;
    width: fit-content;
    // overflow: hidden; // ? causes push buttons issues on filterbar menu
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    border-color: var(--border-color);
    &:not(.btn-icon):not(.btn-icon-nomargin) {
        & > [class*="icon"] {
            margin-right: 5px;
        }
    }

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }

    &.disabled,
    &[disabled="true"],
    &:disabled {
        cursor: default;
        filter: alpha(opacity=65);
        box-shadow: none;
        opacity: 0.65;
    }
    &.mat-mdc-select {
        display: inline-flex !important;
    }

    &.multiline {
        word-wrap: break-word;
        white-space: normal;
        height: fit-content;
        display: block;
        width: inherit;
    }
}

// ? SIZE & SPACINGS
.hx-btn {
    padding: 0px 8px;
    --btn-height: 28px;
    --border-width: 0px;
    border-width: var(--border-width);
    min-height: var(--btn-height);
    height: var(--btn-height);
    line-height: calc(var(--btn-height) - var(--border-width) * 2);
    // ? required for css priority
    &[class*="icon"] {
        line-height: calc(var(--btn-height) - var(--border-width) * 2);
    }
    &.small {
        --btn-height: 24px;
        padding: 0px 6px;
    }
    &.smaller {
        --btn-height: 22px;
        padding: 0px 4px;
    }

    &.big {
        --btn-height: 32px;
        padding: 0px 8px;
    }
    & + .hx-btn {
        &:not(.small):not(.smaller):not(.margin-small) {
            margin-left: 8px;
        }
        &.small {
            margin-left: 6px;
        }
        &.margin-small {
            margin-left: 6px;
        }
        &.smaller {
            margin-left: 6px;
        }
    }
}
// ? COLORS
.hx-btn {
    background: transparent;
    --border-width: 1px;
    border-style: solid;
    color: var(--primary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
    }
}

.btn-primary {
    background: var(--accent);
    border-style: none;
    color: var(--accent-opposite);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--accent-hover);
    }
}

.btn-danger {
    background: var(--error);
    border-style: none;
    color: var(--white);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--error-hover);
    }
}

.btn-secondary {
    background: var(--surface-color);
    border-style: none;
    color: var(--primary-font);
    &.active {
        color: var(--accent);
        background-color: var(--blue-10);
        &:after {
            color: var(--accent);
        }
    }
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--border-color);
    }
}

.btn-white {
    background: var(--main-background);
    --border-width: 1px;
    border-style: solid;
    color: var(--primary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled) {
        background: var(--surface-color);
    }
}

.btn-light {
    color: var(--secondary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
    }
}

.btn-outline {
    background: transparent;
    --border-width: 1px;
    border-style: solid;
    border-color: var(--accent);
    color: var(--accent);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
    }
}

.btn-transparent {
    border-style: none;
    color: var(--secondary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
    }
}

.btn-transparent-color {
    border-style: none;
    color: var(--secondary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        color: var(--accent);
    }
}

.btn-transparent-primary {
    border-style: none;
    color: var(--primary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
    }
}

.btn-transparent-summarize {
    background: var(--summarize-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-style: none;
    // color: var(--primary-font);
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
        color: #09009f;
        -webkit-text-fill-color: initial;
    }
}

.btn-transparent-discovery-rightbar {
    border-style: none;
    color: var(--secondary-accent-font);
    font-size: 16px;
    .hx-sticker {
        color: var(--secondary-accent-font);
    }
    &.selected {
        .hx-sticker {
            color: var(--accent);
        }
    }
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--surface-color);
    }
}

.btn-ai-primary {
    background: var(--summarize-gradient-light);
    border-style: none;
    color: #fff;
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--summarize-gradient-light-20);
        color: #fff;
    }
}

.btn-ai-secondary {
    background: var(--purple-10);
    border-style: none;
    color: #3d2ab2;
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--purple-20);
        color: #3d2ab2;
    }

    & > .gradient {
        background: var(--summarize-gradient);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.btn-sidebar {
    border-style: none;
    --border-width: 0px;
    margin-left: 1px;
    margin-right: 1px;
    &:hover:not([disabled="true"]):not(.disabled):not(:disabled):not(
            .hx-readonly
        ) {
        background: var(--main-background);
        margin-left: 0px;
        margin-right: 0px;
        --border-width: 1px;
        border-style: solid;
    }
}

.btn-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:not(.nocolor):not(.btn-primary):not(.btn-transparent-primary):not(
            .btn-transparent-discovery-rightbar
        ) {
        color: var(--secondary-font);
    }
    [class*="icon"] {
        margin-right: 0 !important;
    }
}

.btn-clickable-icon {
    color: var(--secondary-font);
    justify-content: center;
    [class*="icon"] {
        margin-right: 0 !important;
    }
    border-style: none;
    padding: 0;
    margin: 0;

    &:hover {
        color: var(--primary-font);
        background: none !important;
    }
}
// ? OTHER
.menu-toggle,
.mat-mdc-select-arrow-wrapper {
    position: relative;

    &:after {
        font-family: "harvestr-icons", serif !important;
        content: "\e92d";

        color: var(--secondary-font);
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        padding-left: 5px;
        height: 14px;
        &.small {
            padding-left: 3px;
        }
    }
    &.btn-primary:after {
        color: var(--accent-opposite);
    }
    &.btn-outline:after {
        color: var(--accent);
    }
}

.mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
        display: none;
    }
}

.hx-btn-holder {
    height: 32px;
    min-height: 32px;
    padding: 0px 4px;
    align-items: center;
    align-content: center;
    display: inline-flex;
    justify-content: flex-start;
    cursor: pointer;
    --border-width: 1px;
    border-width: var(--border-width);
    border-color: var(--border-color);
    border-style: solid;
    border-radius: var(--hx-button-radius-px);
    &.disabled,
    &[disabled="true"],
    &:disabled {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        box-shadow: none;
        opacity: 0.65;
    }
    .hx-btn {
        cursor: inherit;
        outline: none;
    }
}

.ai-emphasize:not(.selected) {
    color: var(--purple-text);
    background: var(--summarize-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    &:not(:hover) {
        -webkit-text-fill-color: transparent;
    }
    .hx-sticker {
        -webkit-text-fill-color: initial;
        background: var(--purple-10);
        border-style: none;
        color: #7d2ab2;
        color: var(--purple-text);
    }
}
