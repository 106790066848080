:root {
    --toaster-cta-margin-right-shift: 56px;
}

.app-toaster {
    .mat-mdc-simple-snackbar-action {
        .mat-mdc-button {
            margin-right: var(--toaster-cta-margin-right-shift);
        }
    }
}

.intercom-widget-margin {
    margin-right: var(--toaster-cta-margin-right-shift);
}
