.profile-picture-set {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .profile-picture + .profile-picture {
        margin-left: -8px;
        border: 2px var(--main-background) solid;
        border-radius: 50%;
    }
}
