.branded-secondary-text {
    font-family: TT_Commons;
    color: var(--primary-font);
    font-weight: 415;
    font-size: 14px;
    line-height: normal;     
}

.branded-organization-name {
    font-family: TT_Commons;
    font-weight: 785;
    line-height: 30px;
    font-size: 24px;
    background: linear-gradient(98deg, #FF8049 43.47%, #F7453A 106.71%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.branded-title {
    font-family: TT_Commons;
    font-weight: 490;
    line-height: 30px;
    font-size: 24px;
    color: var(--brand-secondary-font);

}
